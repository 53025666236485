import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatepickerField = ({ name, label, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <div className="flex flex-col">
      <p className={`text-lightDark text-base mb-2 `}>{label}</p>
      <div className={`w-[100%]  p-2 border-1 border-[#cccccc] flex gap-[1.2rem] py-[0.7rem] items-center bg-[#fcfcfc] shadow rounded-[5px] text-black text-base `}>
        <DatePicker
          {...field}
          {...props}
          selected={field.value}
          onChange={(val) => {
            setFieldValue(name, val);
          }}
          className="bg-[#fcfcfc] outline-none "
          placeholderText="Seled Date"
          dateFormat="yyyy-MM-dd"
        />

      </div>
      <div className="text-red-700 text-sm">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default DatepickerField;
