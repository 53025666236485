import React from "react";
import Actions from "../Table/TableAction";
import Tooltip from "@mui/material/Tooltip";
// import Image from 'next/image';
// import { Lato } from "next/font/google";

interface cardProps {
  image: any;
  firstname: string;
  lastname: string;
  phone: string | number;
  position: string;
  id: string | number;
  department: string;
  email: string;
  date: any;
  handleDelete: (id: any) => void;
}

const ContactCard: React.FC<cardProps> = ({
  image,
  firstname,
  lastname,
  position,
  id,
  department,
  email,
  date,
  phone,
  handleDelete,
}) => {
  return (
    <div className="bg-lighterGray1 shadow-white shadow-2xl rounded-xl w-[360px] h-[320px] py-4 px-2 hover:bg-[#fbc2c2] transition-all duration-900  hover:translate-y-1  flex flex-col justify-between">
      <div>
        <div className="flex  flex-col">
          <img
            src={image}
            alt={firstname}
            className="h-[60px] w-[60px] rounded-lg"
          />
        </div>
        <div className="flex flex-row justify-between items-center mb-3">
          <div className="flex flex-col">
            <span className={` text-black text-xl`}>
              {firstname} {lastname}
            </span>
            <span className={`text-[#6F6F6F] font-bold text-sm `}>
              {position}
            </span>
          </div>
          <div className={` text-black`}>
            <Actions handleDelete={() => handleDelete(id)} />
          </div>
        </div>
      </div>
      <div className="bg-white  rounded-xl p-2 shadow-md mt-auto">
        <div className="flex flex-row justify-between mb-4">
          <div className="flex flex-col">
            <span className={`text-[#6F6F6F] font-bold text-sm `}>
              Department
            </span>
            <span className={` text-lightDark`}> {department}</span>
          </div>
          <div className="flex flex-col justify-between">
            <span className={`text-[#6F6F6F] font-bold text-sm  text-right`}>
              Date Joined
            </span>
            <span className={` text-lightDark`}>{date}</span>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-row justify-between">
            <span className={` text-[#6F6F6F] text-sm text-[#6F6F6F`}>
              {" "}
              Email{" "}
            </span>
            <span className={` text-[#6F6F6F] text-sm text-[#6F6F6F`}>
              Phone
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <span className={` text-base text-lightDark`}>
              {" "}
              <Tooltip className="truncate w-40" title={email}>
                <div>{email}</div>
              </Tooltip>
            </span>
            <span className={` text-base text-lightDark`}>{phone}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
