import React, { createContext, useContext, useState } from "react";
import { getAuthUser, getAccessTokenFromCookies } from "../helper";

interface GlobalContextProps {
  token: string | null;
  user: VaultUser | null;
  setUser: React.Dispatch<React.SetStateAction<VaultUser | null>>;
  logout: () => void;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

interface VaultUser {
  id: string;
  firstname: string;
  name: string;
  email: string;
  department: string;
  picture: string;
}

const GlobalContext = createContext<GlobalContextProps | null>(null);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    );
  }
  return context;
};

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const currentUser = getAuthUser();
  const accessToken = getAccessTokenFromCookies();
  const [user, setUser] = useState<VaultUser | null>(
    currentUser ? currentUser : null
  );
  const [token, setToken] = useState<string | null>(
    accessToken ? accessToken : null
  );

  const logout = () => {
    // window.location.href = navigationRoutes.logout;
  };
  return (
    <GlobalContext.Provider
      value={{
        token,
        user,
        setUser,
        logout,
        setToken,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
