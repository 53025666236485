import React, { FC } from "react";
import { Field, useFormikContext } from "formik";
import ErrorMessage from "./Error";
import { FormikValues } from "formik";
// import { Lato } from "next/font/google";


interface SelectFieldProps {
    name: string;
    options: Array<{ value: string | number; label: string | number}>;
    placeholder?: string;
    label?: string;
    icon?: React.ReactElement;
    onChange?: (value: string) => void;
}

// const SmallerFont = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })



export const SelectField: FC<SelectFieldProps> = ({
    name,
    options,
    placeholder,
    icon,
    label
}) => {
    const {
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        values,
    } = useFormikContext<FormikValues>();

    return (
        <React.Fragment>
            <div className="flex flex-col w-full">
                <p className={`text-lightDark text-base mb-2 `}>{label}</p>
                <div className="w-[100%] p-2 h-[50px]  border-[#cccccc] flex gap-[1.2rem] py-[0.7rem] item-center bg-[#fcfcfc] shadow rounded-[5px]  text-base">
                    {/* {icon && <div className="icon-container">{icon}</div>} */}
                    <Field
                        as="select"
                        className="w-[100%] bg-[#fcfcfc] outline-none px-2"
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        onBlur={() => setFieldTouched(name, true)}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            setFieldValue(name, e.target.value)
                        }
                        value={values[name] as string}
                    >
                        {placeholder && (
                            <option value="" disabled className="mx-4 text-lightDark">
                                {placeholder}
                            </option>
                        )}
                        {options?.map((option) => (
                            <option key={option.value} value={option.value} className="mx-4">
                                {option.label}
                            </option>
                        ))}
                    </Field>

                </div>
                <ErrorMessage
                    error={errors[name] as string}
                    visible={touched[name] as boolean}
                />
            </div>

        </React.Fragment>
    );
};
