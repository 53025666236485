import React from 'react'

const ComingSoon = () => {
  return (
    <div className="flex w-full h-screen items-center justify-center">
      Coming Soon!
      </div>
  )
}

export default ComingSoon;