import React, { useState, useEffect } from "react";
import Screen from "../../components/Screen";
import ProfileHeaderSection from "../../components/ProfileHeaderSection";
import HeaderDescription from "../../components/HeaderDescription";
import AppForm from "../../components/forms/AppForm";
import { AppField } from "../../components/forms/Field";
import Button from "../../components/forms/Button";
import * as Yup from "yup";
import { SelectField } from "../../components/forms/SelectField";
import { employerOfMonth } from "../../services/api";
import LoaderComponent from "../../components/LoadingComponent";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGlobalContext } from "../../context/globalContext";

const validationSchema = Yup.object().shape({
  employeeName: Yup.string().required().label("name of employee"),
  employeeDepartment: Yup.string().required().label("department of employee"),
  email: Yup.string().required().label("email of employee"),
  month: Yup.string().required().label("month of recognizion"),
  year: Yup.string().required().label("year of recognizion"),
});

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
const year = [
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

const departments = [
  { value: "hr", label: "HR" },
  { value: "engineering", label: "Engineering" },
  { value: "marketing", label: "Marketing" },
  { value: "finance", label: "Finance" },
  { value: "admin", label: "Admin" },
  { value: "operation", label: "Operation" },
  { value: "sales", label: "Sales" },
  { value: "customer_service", label: "Customer Service" },
  { value: "others", label: "Others" },
];

const CreateEmployee = () => {
  const { user, token } = useGlobalContext();
  const vaultId = user?.id;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value: any) => {
    setLoading(true);
    const res = await employerOfMonth(value);
    const result = res?.data;

    if (result.success === false) {
      toast.error("Current month already has an employee assigned to it!", {});
    } else {
      toast.success("Employee of the month has been updated!", {});
    }
    setLoading(false);
    navigate("/dashboard");
  };

  return (
    <div className="w-full h-screen">
      <Screen>
        <LoaderComponent loading={loading} />

        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Admin"}
          subtext={"Growth"}
          subsubtext={"Create-Employee of the month"}
        />

        <div className="mt-24 mx-auto h-6/12 w-7/12">
          <AppForm
            initialValues={{
              employeeName: "",
              email: "",
              month: "",
              year: "",
              employeeDepartment: "",
              user_id: vaultId,
            }}
            onSubmit={(values: any) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            <div className="my-4">
              <AppField
                name={"employeeName"}
                label={`Name of employee`}
                height={24}
                placeholder="Please enter the name of Employee"
              />
            </div>

            <div className="my-4">
              <AppField
                name={"email"}
                label={`email of employee`}
                height={24}
                placeholder="Please enter the email address of Employee"
              />
            </div>

            <div className="my-4">
              <SelectField
                name="employeeDepartment"
                options={departments}
                placeholder="department"
                label="Select month"
              />
            </div>

            <div className="my-4">
              <SelectField
                name={"month"}
                options={months}
                placeholder="month"
                label="Select month"
              />
            </div>
            <div className="my-4">
              <SelectField
                name={"year"}
                options={year}
                placeholder="year"
                label="Select year"
              />
            </div>

            <div className="flex mx-auto justify-center mt-8">
              <Button
                width="60"
                text={"SUBMIT"}
                textColor="white"
                bg={"lightRed"}
              />
            </div>
          </AppForm>
        </div>
      </Screen>
    </div>
  );
};

export default CreateEmployee;
