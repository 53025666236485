import React from "react";
import Loader from "./Loader";

const LoaderComponent = ({ loading }: any) => {
    return (
        <div className="flex items-center justify-center">
            {loading && <Loader />}
        </div>
    );
};

export default LoaderComponent;