import React from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";

type I_TableLayout = {
  getTableProps: any;
  headerGroups: any;
  getTableBodyProps: any;
  retrieveData: Record<string, any>[];
  page?: any;
  prepareRow: any;
  state: { selectedRowIds: any };
};

const TableLayout = (props: I_TableLayout) => {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    retrieveData,
    page,
    prepareRow,
  } = props;

  const handleRowClick = (rowId: string) => {};

  return (
    <table className="w-full table-auto  " {...getTableProps()}>
      <thead className="bg-white rounded-lg">
        {headerGroups.map((headerGroup: any, index: any) => (
          <tr
            key={index}
            {...headerGroup.getHeaderGroupProps()}
            className={`text-lightDark    shadow`}
          >
            {headerGroup.headers.map((column: any, index: any) => (
              <th
                className="px-6 py-3 text-lightDark text-left font-lato text-[18px] border-b-lightGray"
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div className="flex flex-row font-lato text-[12px]">
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsArrowUpShort />
                      ) : (
                        <BsArrowDownShort />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="text-[12px]">
        {retrieveData &&
          retrieveData?.length > 0 &&
          page?.map((row: any, index: any) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                {...row.getRowProps()}
                className="border-b-[1px] border-b-[#C0C0C0] text-[12px]"
                onClick={() => handleRowClick(row)}
              >
                {row.cells.map((cell: any, index: any) => {
                  return (
                    <td
                      className="whitespace-nowrap px-6 py-4 text-[12px]"
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default TableLayout;
