import { Field, useFormikContext } from "formik";
import React, { FC } from "react";
import ErrorMessage from "./Error";
import { FormikValues } from "formik";
// import { Lato } from "next/font/google";

// const SmallerFont = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

interface AppFieldProps {
  name: string;
  type?: string;
  as?: string;
  height?: number | string;
  icon?: React.ReactElement;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  cols?: any;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AppField: FC<AppFieldProps> = ({
  name,
  as,
  cols,
  icon,
  label,
  disabled,
  height,
  ...rest
}) => {
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext<FormikValues>();

  return (
    <React.Fragment>
      <div className="flex flex-col">
        <p className={`text-lightDark text-base mb-2 mt-2`}>{label}</p>
        <div
          className={`w-[100%]  p-2 border-1 border-[#cccccc] flex gap-[1.2rem] py-[0.7rem] items-center bg-[#fcfcfc] shadow rounded-[5px] text-black text-base `}
        >
          {/* <div className="text-bloodRed">{icon && <div className="icon-container">{icon}</div>}</div> */}
          <Field
            as={as}
            cols={cols}
            // rows='10'
            disabled={disabled}
            // className={`w-full bg-[#fcfcfc] outline-none text-[15px] ${height}`}
            className={`w-full bg-[#fcfcfc] outline-none text-[15px] px-2 h-${height}`}
            id={name}
            name={name}
            onBlur={() => setFieldTouched(name, true)}
            onChange={(e: any) => setFieldValue(name, e.target.value)}
            value={values[name] as string}
            {...rest}
          />
        </div>
        <ErrorMessage
          error={errors[name] as string}
          visible={touched[name] as boolean}
        />
      </div>
    </React.Fragment>
  );
};
