import React from "react";

import { GoogleLogin } from "@react-oauth/google";

import { useNavigate } from "react-router-dom";
// import { jwtDecode } from "jwt-decode";
import Loader from "../components/Loader";

import { useSignInUser } from "../hooks/useAuth";

const Login = () => {
  const { signInUserMutate, isPending } = useSignInUser();
  const navigate = useNavigate();

  const HandleLogin = async (credentialResponse: any) => {
    try {
      const response = await credentialResponse;
      // const res = jwtDecode(response?.credential);
      signInUserMutate(
        { auth_token: response?.credential },
        {
          onSuccess: (data) => {
            navigate("/dashboard");
          },
          onError: (error) => {
            console.log("Error", error);
          },
        }
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className="w-full h-screen bg-orange flex items-center justify-center">
      {isPending && <Loader />}
      <div className=" ">
        <GoogleLogin
          onSuccess={(credentialResponse) => HandleLogin(credentialResponse)}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>
    </div>
  );
};

export default Login;
