"use client";
import React, { useState, useEffect } from "react";

import Annoucementcard from "./Annoucementcard";
import { country, donunts } from "./image";
import {
  getAllAnnouncement,
  getEmployeeOfMonth,
  getUserStat,
} from "../services/api";
import LoaderComponent from "./LoadingComponent";

interface Employee {
  employee_name: string;
  employee_department: string;
  image: string;
  month: any;
}

const Annoucement = () => {
  const [data, setData] = useState([]);
  // const [stat, setStat] = useState([])
  const [stat, setStat] = useState<number>(0);
  const [loading, setloading] = useState(false);

  const [employee, setEmployee] = useState<Employee | null>(null);

  const fetchData = async () => {
    try {
      setloading(true);
      const response = await getAllAnnouncement();

      setData(response?.data?.data?.reverse());
    } catch (error) {
      setloading(false);
      console.log("error", error);
    } finally {
      setloading(false);
    }
  };

  const fetchEmployee = async () => {
    try {
      const response = await getEmployeeOfMonth();
      setEmployee(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchStat = async () => {
    try {
      const response = await getUserStat();
      const data = response?.data?.count;

      // console.log("datafornumberofemployee", data)

      setStat(
        Number(data?.nigeria || 0) +
          Number(data?.kenya || 0) +
          Number(data?.ethiopia || 0)
      );
    } catch (error) {
      console.log("erro", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchStat();
    fetchEmployee();
  }, []);

  // @ts-ignore
  const monthIndex = (employee?.month ?? 0) - 1;
  const monthName = MONTHS[monthIndex >= 0 && monthIndex < 12 ? monthIndex : 0]; // Handle out of range index

  return (
    <div
      className="mt-[38px] flex flex-col lg:justify-between gap-2 p-2"
      style={{ height: "calc(100% - 7%)" }}
    >
      <div className="md:country-composition-height bg-white shadow-lg w-full rounded-lg flex flex-col  justify-center">
        <div className="flex justify-between">
          <div className="text-lightDark p-2 font-semibold">
            Country Composition
          </div>
          <div>
            <p className="text-right p-2 text-sm">{stat} Employees</p>
          </div>
        </div>
        <img
          src={donunts}
          alt="Donut chart"
          className="w-[120px] h-[120px] flex mx-auto"
        />

        <div className="flex mx-auto">
          <img src={country} alt="Donut chart" className="w-[220px]" />
        </div>
      </div>

      <div className="announcement-height bg-white  shadow-lg rounded-lg overflow-y-auto no-scrollbar">
        <div>
          <p className={`font-extrabold p-2 text-lightDark `}>Announcement</p>
        </div>
        {!loading ? (
          <>
            {/* <p className={`p-2 text-lightDark text-md`}>Priority</p> */}
            {data?.map(
              (ele: { title: string; id: number; created: string }) => {
                return (
                  <div className="no-scrollbar" key={ele?.id}>
                    <Annoucementcard
                      id={ele.id}
                      text={ele?.title}
                      time={ele?.created}
                      // title={ele.title}
                    />
                  </div>
                );
              }
            )}
          </>
        ) : (
          <LoaderComponent loading={true} />
        )}
      </div>

      <div className="lg:employee-height bg-white w-full  shadow-lg flex flex-row justify-between items-center rounded-lg px-2 py-1">
        {employee ? (
          <>
            <div>
              <h1 className={`mt-1 text-1xl text-lightDark font-semibold`}>
                Employee of the month
              </h1>

              <div className="flex gap-2">
                <p className={`text-sm text-lightDark  font-semibold `}>
                  Name:
                </p>

                <p className={`text-sm text-lightDark capitalize `}>
                  {employee?.employee_name}
                </p>
              </div>
              <div className="flex gap-2">
                <p className={`text-sm text-lightDark font-semibold `}>
                  {" "}
                  Department:
                </p>
                <p className={`text-sm text-lightDark capitalize `}>
                  {" "}
                  {employee?.employee_department}
                </p>
              </div>
              <div className="bg-lightRed w-28 rounded-r-lg  text-white p-2 text-center mb-2 mt-2 hover:bg-lightRed-opacity-100 transition duration-300 uppercase">
                {monthName}
              </div>
            </div>
            <div className="w-20 h-20 flex items-center justify-center rounded">
              <img src={employee?.image} alt="profile" className="rounded-lg" />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col h-32 ">
              <h1 className={`mt-1 text-1xl text-lightDark font-semibold text-center`}>
                Employee of the month
              </h1>
              <p className="flex items-center justify-center h-full px-10 text-center">
                No employee of the month
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Annoucement;

const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
