import React from 'react'


interface textProps {
    text: string
}

const HeaderBox = ({ text }: textProps) => {
    return (
        <div className="p-4 bg-lightDark text-white text-2xl rounded">{text}</div>
    )
}

export default HeaderBox