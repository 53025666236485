import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// import { Lato } from "next/font/google";

interface cardProps {
  id: any;
  title?: string;
  text: string;
  time: string;
  onClick?: any;
}

// const LatoFont = Lato({
//     subsets: ["latin-ext"],
//     weight: "400",
// });

const Annoucementcard = ({ text, title, time, id }: cardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/single-announcement/${id}`);
  };

  return (
    <div
      className={`shadow-sm w-11/12 px-2 py-1 mx-auto rounded bg-lightGray flex flex-col mt-2 mb-2 cursor-pointer`}
      onClick={handleClick}
    >
      <p className={`text-base text-black capitalize font-semibold`}>{text}</p>
      <p className="text-sm text-slate-400">
        Created at {dayjs(time).format("DD MMM YYYY")}
      </p>
    </div>
  );
};

export default Annoucementcard;
