import { Column } from "react-table";
import React from "react"; 

const AllUsersTableHeader = (
  action: (item: any) => void
): readonly Column<any>[] => [
  {
    Header: "Name",
    accessor: "firstname",
    Cell: ({ row }: any) => {
      const { firstname, lastname, email, image } = row.original;
      return (
        <div className="text-lightDark flex gap-1 items-center  rounded text-[12px]">
          <p className="w-[32px] h-[32px]">
            <img
              src={image}
              className="w-[30px] h-[30px] rounded-lg"
              alt="profile"
            />
          </p>
          <div>
            <p>
              {firstname} {lastname}
            </p>
            <p className="text-[#6B788E]">{email}</p>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Phone number",
    accessor: "phone",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return <p className="text-lightDark  text-[12px]">{value}</p>;
      }
      return "N/A";
    },
  },
  {
    Header: "City",
    accessor: "city",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return <p className="text-lightDark  text-[12px]">{value}</p>;
      }
      return "N/A";
    },
  },

  {
    Header: "Role",
    accessor: "job_role",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return <p className="text-lightDark  text-[12px]">{value}</p>;
      }
      return 0;
    },
  },
  {
    Header: "Department",
    accessor: "department",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return (
          <div>
            <p className="text-lightDark bg-[#F5F6F7] p-1 rounded text-[12px]">
              {value}
            </p>
          </div>
        );
      }
      return "N/A";
    },
  },
  {
    Header: "Date Joined",
    accessor: "datejoined",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return <p className="text-lightDark   text-[12px]">{value}</p>;
      }
      return "N/A";
    },
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }: { value: string }) => {
      if (value) {
        return (
          <p className="text-[#027A48] w-full text-base">
            <span className="w-10/12 bg-[#ECFDF3] p-1 rounded">{value}</span>
          </p>
        );
      }
      return "N/A";
    },
  },

  {
    id: "action",
    accessor: (item: any) => {
      return <>{action(item)}</>;
    },
  },
];

export default AllUsersTableHeader;
