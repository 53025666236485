import React from "react";
import { Button } from "../forms/DashboardButton";

const DashBoardModal = ({ setModal }: any) => {
  const handleclose = () => {
    setModal(false);
  };

  const handleProceed = () => {
    setModal(false);
    window.open("https://hr.lifebankcares.com", "_blank");
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40 bg-[rgba(136,133,133,0.7)]">
      <div className="h-auto lg:w-[35%] shadow-md z-50 bg-white rounded-xl p-4">
        <div className="flex justify-between">
          <p className={`text-black  text-[20px] font-lato`}>SME</p>
          <p
            className="text-lightRed bg-orange flex items-center justify-center h-8 w-8 rounded-2xl cursor-pointer"
            onClick={handleclose}
          >
            X
          </p>
        </div>

        <p className="my-1 text-center">
          You are about to visit the SME page outside the Vault app. Do you want
          to proceed with that?
        </p>
        <div
          className={
            "mx-auto justify-center items-center bg-bloodRed rounded relative top-1"
          }
        >
          <Button bg={"bloodRed"} text={"Proceed"} onClick={handleProceed} />
        </div>
      </div>
    </div>
  );
};

export default DashBoardModal;
