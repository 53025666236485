"use client"
import React from 'react'
// import { Lato } from "next/font/google";

import Screen from '../components/Screen';
import ProfileHeaderSection from '../components/ProfileHeaderSection';
import HeaderDescription from '../components/HeaderDescription';
import HeaderBox from '../components/Box/HeaderBox';
import { BackButton, FowardButton } from '../components/forms/DashboardButton';

import { useNavigate } from "react-router-dom"
import { value } from '../components/image';





const Values = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("/organization")
    }
    const handlePrev = () => {
        navigate("/mission")
    }
    return (
        <div className="bg-white w-full">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'About Value'} />
                <p className={` marker:text-4xl text-lightDark my-4`}>About Value</p>
                <div className="">
                    <HeaderBox text="Revolutionizing African healthcare through technology" />
                </div>
                <div className={`  mt-4 text-basetext leading-7 text-left`}>

                    We are a team of passionate, driven and committed people who are excited about the opportunity to improve healthcare in Africa. We are proud of our work and we are eager to roll up our sleeves to get things done.
                </div>


                <div className="absolute top-4">

                </div>
                <div className="flex mx-auto justify-center w-full my-8">
                    <img src={value} alt="team/value" className="rounded h-60 w-full object-fill" />
                </div>

                <div className="my-2 flex gap-4 right-2 absolute bottom-4">
                    <BackButton text={'Previous'} onClick={handlePrev} subText={'Mission& Vision'} />
                    <FowardButton text={'Next'} onClick={handleNext} subText={'Organization chart'} />
                </div>
            </Screen>


        </div>
    )
}

export default Values