"use client";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { backgroundImage } from "../../components/image";

import AOS from "aos";
import "aos/dist/aos.css";

import Screen from "../../components/Screen";
import ProfileHeaderSection from "../../components/ProfileHeaderSection";
import HeaderDescription from "../../components/HeaderDescription";
import HeaderBox from "../../components/Box/HeaderBox";
import {
  BackButton,
  FowardButton,
} from "../../components/forms/DashboardButton";
import { Clickablebox } from "../../components/ClickableCircle";

const Organization = () => {
  const navigate = useNavigate();

  const handleprev = () => {
    navigate("/value");
  };
  const handleNext = () => {
    navigate("/directory");
  };
  const handleCto = () => {
    navigate("/cto");
  };
  const handlefinance = () => {
    navigate("/finance");
  };
  const handleGrowth = () => {
    navigate("/vp-growth");
  };

  const handleLbn = () => {
    navigate("/lbn");
  };
  const handleLBE = () => {
    navigate("/lbe");
  };
  const handleLBK = () => {
    navigate("/lbk");
  };
  const handleComms = () => {
    navigate("/communication");
  };

  const handleOpps = () => {
    navigate("/operations");
  };

  const handleBoat = () => {
    navigate("/boat");
  };

  const handlePeople = () => {
    navigate("/people");
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-full">
      <Screen>
        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Dashboard"}
          subtext={"Our Team"}
          subsubtext={"Mission & Vison"}
        />

        <div>
          <p className={`text-lightDark text-3xl mt-2 mb-4 `}>
            Organization Chart
          </p>
        </div>
        <div className="">
          <HeaderBox text="A Visual guide into Lifebank structure and hierarchy" />
        </div>
        <div
          className="mt-4 mb-4 p-2 bg-no-repeat object-fit bg-cover h-[60%] rounded"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="2000"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className=" mb-4">
              <div className="h-20 w-40 rounded shadow-md bg-white flex items-center mx-auto justify-center text-lightDark hover:transition hover:duration-300 ease-in-out hover:bg-bloodRed hover:text-white">
                <p className="text-lightDark hover:text-white px-5 py-4 text-center flex items-center justify-center">
                  Board of Director
                </p>
              </div>
            </div>
            <div className=" text-lightDark  h-40 w-40 mt-2 mb-2 rounded-[8rem] shadow-md bg-white flex item-center mx-auto justify-center hover:text-white  hover:bg-bloodRed">
              <p
                className={` hover:text-white text-center flex  items-center justify-center `}
              >
                Group CEO
              </p>
            </div>
          </div>

          <div className="mt-20 flex flex-col lg:flex-row gap-4">
            <Clickablebox
              title={"Director of people"}
              margin={24}
              onClick={handlePeople}
            />
            <Clickablebox title={"C.E.O LBN"} onClick={handleLbn} margin={16} />
            <Clickablebox title={"C.T.O"} onClick={handleCto} margin={16} />
            <Clickablebox
              title={"VP. Finance"}
              onClick={handlefinance}
              margin={16}
            />
            <Clickablebox
              title={"VP. Growth"}
              onClick={handleGrowth}
              margin={16}
            />
            <Clickablebox
              title={"VP. Operations"}
              margin={16}
              onClick={handleOpps}
            />
            <Clickablebox title={"ED. Boat"} margin={16} onClick={handleBoat} />
            <Clickablebox title={"C.E.O LBK"} onClick={handleLBK} margin={16} />
            <Clickablebox title={"C.E.O LBE"} margin={16} onClick={handleLBE} />
            <Clickablebox
              title={"Director of communication"}
              margin={24}
              onClick={handleComms}
            />
          </div>
        </div>

        <div className="my-2 flex bottom-2 gap-4 w-full justify-end">
          <BackButton
            text={"Previous"}
            onClick={handleprev}
            subText={"Value"}
          />
          <FowardButton
            text={"Next"}
            onClick={handleNext}
            subText={"Directory"}
          />
        </div>
      </Screen>
    </div>
  );
};

export default Organization;
