/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";
import React, { useState, useEffect, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Screen from "../components/Screen";
import ProfileHeaderSection from "../components/ProfileHeaderSection";
import { Button } from "../components/forms/DashboardButton";
import { BsPlusSquareDotted } from "react-icons/bs";
import ContactCard from "../components/Box/ContactCard";
import TableLayout from "../components/Table/TableLayout";
import TablePagination from "../components/Table/TablePagination";
import TableHeader from "../components/Table/TableColumn";
import TableAction from "../components/Table/TableAction";
import DataTableCard from "../components/Table/DataTableCard";
import Modal from "../components/modal/AddEmployer";
import { box, list, people } from "../components/image";
import TableSearch from "../components/Table/TableSearch";
import Actions from "../components/Table/TableAction";
import { getUser } from "../services/api";
import { useGlobalContext } from "../context/globalContext";
import TransitionsModal from "../components/modal/CustomModal";
import { useGetEmployees, useDeleteEmployee } from "../hooks/useEmployee";

const Directory = () => {
  const { user, token } = useGlobalContext();
  // const { employee } = useGetEmployees();
  const { mutateDeleteEmployee, isPending } = useDeleteEmployee();
  const [showModal, setShowModal] = useState(false);
  const [showList, setShowList] = useState(true);
  const [showBox, setShowBox] = useState(false);
  const [data, setData] = useState([]);
  // @ts-ignore
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;

  const userdept = user?.department;
  const dept = userdept;

  useEffect(() => {
    const fectch = async () => {
      const res = await getUser();
      const data = res?.data?.data;

      setData(data) as any;
    };
    fectch();
  }, []);

  const handleExport = () => {};

  const handleAdd = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleBox = () => {
    setShowList(false);
    setShowBox(true);
  };

  const handlelist = () => {
    setShowBox(false);
    setShowList(true);
  };
  const [edit, setEdit] = useState<{} | null>(null);
  const handleEdit = (item: any) => {
    console.log(item);
    setEdit(item);
  };

  const [deleteEmployee, setDeleteEmployee] = useState<{} | null>(null);
  const handleDelete = (item: any) => {
    console.log(item);
    setDeleteEmployee(item.id ?? item);
  };

  const confirmDelete = () => {
    console.log(deleteEmployee);
    mutateDeleteEmployee(deleteEmployee, {
      onSuccess: (data) => {
        console.log(data);
        setDeleteEmployee(null);
        window.location.reload();
      },
    });
  };
  const handleCloseEdit = () => {
    setEdit(null);
  };

  const columns: any = useMemo(
    () =>
      TableHeader((item) => {
        if (dept === "People Operations") {
          return (
            <Actions
              handleClick={() => handleEdit(item)}
              handleDelete={() => handleDelete(item)}
            />
          );
        }
      }),
    [dept]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    state,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    selectedFlatRows,
    // @ts-ignore
    state: { selectedRowIds },
    // @ts-ignore
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      // initialState: { pageIndex: 0 },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  //   @ts-ignore
  const { globalFilter, pageIndex, pageSize } = state;
  const currentData = data?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value - 1);
  };

  return (
    <div className="bg-white w-full">
      <TransitionsModal
        open={!!deleteEmployee}
        setOpen={setDeleteEmployee}
        title={"Delete Employee?"}
        handleCloseModal={() => setDeleteEmployee(null)}
      >
        <div className="flex flex-col items-center w-full">
          <p className="text-center text-lightDark">
            Are you sure you want to delete this employee?
          </p>
          <p>This action is Irreversible!</p>
        </div>
        <div className="flex-row gap-4 flex justify-between mx-auto mt-8">
          <button
            className={`bg-customGreen w-${40} rounded text-center  h-[50px] py-3 cursor-pointer transition text-lightDark duration-300 ease-in-out hover:text-white hover:bg-opacity-80`}
            onClick={() => setDeleteEmployee(null)}
          >
            <p className={`text-${"white"} hover:text-white`}>Cancel</p>
          </button>
          <button
            className={`bg-bloodRed w-${40} rounded text-center  h-[50px] py-3 cursor-pointer transition text-lightDark duration-300 ease-in-out hover:text-white hover:bg-opacity-80`}
            onClick={confirmDelete}
          >
            <p className={`text-${"white"} hover:text-white`}>Delete</p>
          </button>
          {/* <Button text={'Approve'} bg={'lightRed'} textColor='white' width={"32"} /> */}
          {/* <Button text={'Deny'} bg={'bloodRed'} textColor='white' width={"32"} /> */}
        </div>
      </TransitionsModal>
      <TransitionsModal
        open={!!edit}
        setOpen={setEdit}
        title={"Edit Employee"}
        handleCloseModal={handleCloseEdit}
      >
        this modal
      </TransitionsModal>{" "}
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          closeModal={closeModal}
        />
      )}
      <Screen>
        <ProfileHeaderSection />
        <div className=" border border-[#C0C0C0] w-full lg:mt-2 mt-14 mb-2" />
        <div className="mt-4 flex flex-row justify-between items-center">
          <p className={`text-lightDark `}>Employees</p>
          {dept === "People Operations" && (
            <div className="flex flex-row justify-between gap-2 mx-4">
              {/* <Button text="Export" icon={<CiExport size={22} />} onClick={handleExport} bg="white" /> */}
              <Button
                text="New Employee"
                icon={<BsPlusSquareDotted size={22} color="white" />}
                onClick={handleAdd}
                bg="bloodRed"
              />
            </div>
          )}
        </div>
        <div className=" border border-[#C0C0C0] w-full mt-2 mb-2" />

        <div className="rounded-md border border-[#C0C0C0] py-2 flex flex-row justify-between px-2 mb-4">
          <div className="mb-2 mt-2 flex items-center py-2 rounded-md border-[#C0C0C0] w-[300px] px-2">
            <TableSearch
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder={"Employer name/department"}
            />
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="px-2 cursor-pointer" onClick={handleBox}>
              <span>
                <img src={box} alt="" />
              </span>
            </div>
            <div className="px-2 cursor-pointer" onClick={handlelist}>
              <span>
                <img src={list} alt="" />
              </span>
            </div>
          </div>
        </div>

        <div>
          {showList && (
            <div>
              <DataTableCard>
                <div className="overflow-x-auto w-full">
                  <TableLayout
                    getTableProps={getTableProps}
                    headerGroups={headerGroups}
                    getTableBodyProps={getTableBodyProps}
                    retrieveData={data}
                    page={page}
                    prepareRow={prepareRow}
                    state={{
                      selectedRowIds: undefined,
                    }}
                  />
                </div>

                <TablePagination
                  gotoPage={gotoPage}
                  // onPageChange={handleChange({gotoPage})}
                  onPageChange={handleChange}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                />
              </DataTableCard>
            </div>
          )}

          {showBox && (
            <div>
              <div className="flex flex-wrap justify-between items-center mx-auto gap-8 overflow-auto">
                {currentData?.map(
                  (ele: {
                    image: string;
                    id: string;
                    department: string;
                    job_role: string;
                    firstname: string;
                    lastname: string;
                    datejoined: string;
                    email: string;
                    phone: string;
                  }) => (
                    <ContactCard
                      handleDelete={handleDelete}
                      image={ele?.image}
                      id={ele.id}
                      department={ele.department}
                      key={ele.id}
                      position={ele.job_role}
                      firstname={ele.firstname}
                      lastname={ele.lastname}
                      date={ele.datejoined}
                      email={ele.email}
                      phone={ele.phone}
                    />
                  )
                )}
              </div>
              <div className="flex justify-center mt-4">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 0))
                  }
                  disabled={currentPage === 0}
                  className={`mx-1 px-3 py-1 rounded ${
                    currentPage === 0 ? "bg-gray-300" : "bg-blue-500 text-white"
                  }`}
                >
                  Previous
                </button>
                {Array?.from(
                  { length: Math.ceil(data.length / itemsPerPage) },
                  (_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentPage === index
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  )
                )}
                <button
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(
                        prev + 1,
                        Math.ceil(data.length / itemsPerPage) - 1
                      )
                    )
                  }
                  disabled={
                    currentPage >= Math.ceil(data.length / itemsPerPage) - 1
                  }
                  className={`mx-1 px-3 py-1 rounded ${
                    currentPage >= Math.ceil(data.length / itemsPerPage) - 1
                      ? "bg-gray-300"
                      : "bg-blue-500 text-white"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </Screen>
    </div>
  );
};

export default Directory;
