import React, { useEffect, useState } from "react";
import { singleRequest, treatRequest } from "../../services/api";

import LoaderComponent from "../LoadingComponent";
import { toast } from "react-toastify";

const RaiseModal = ({ setShowModal, details }: any) => {
  const [data, setData] = useState([]) as any;
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    const res = await singleRequest(details?.id);
    const data = res?.data?.data;
    setData(data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleclose = () => {
    setShowModal(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    const response = await treatRequest(data?.id, {
      status: "accepted",
    });
    setLoading(false);
    toast.success("Request successfully treated!", {});
    setShowModal(false);
  };
  const handleReject = async () => {
    setLoading(true);
    const response = await treatRequest(data?.id, {
      status: "rejected",
    });
    setLoading(false);
    toast.success("Request successfully treated!", {});
    setShowModal(false);
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40 bg-[rgba(136,133,133,0.7)]">
      <div className="lg:h-fit lg:w-[50%] shadow-md z-50 bg-white rounded-xl p-4">
        <LoaderComponent loading={loading} />

        <div className="flex justify-between">
          <p className={`text-black  text-[20px] font-lato`}>Requests</p>
          <p
            className="text-lightRed bg-orange flex items-center justify-center h-8 w-8 rounded-2xl cursor-pointer"
            onClick={handleclose}
          >
            X
          </p>
        </div>
        <div className="h-auto border rounded lg:w-9/12 flex mx-auto mt-8 flex-col border-[#C0C0C0]">
          <List title="Employee" text={data.email} />
          <List title="Request ID" text={data.id} />
          <List title="Role" text={data?.role} />
          <List title="Department" text={data.current_department} />
          <List title="Email" text={data.email} />

          <List title="Request type" text={data?.type} />
          <List title="Status" text={data?.status} />
          <List title="Your monthly okrs" text={data?.okr} />

          <List title="Why would u like a raise" text={data?.reason} />
        </div>
        <div className="flex-row gap-4 lg:w-7/12 flex justify-between mx-auto mt-8">
          <button
            className={`bg-customGreen w-${40} rounded text-center  h-[50px] py-3 cursor-pointer transition text-lightDark duration-300 ease-in-out hover:text-white hover:bg-opacity-80`}
            onClick={handleApprove}
          >
            <p className={`text-${"white"} hover:text-white`}>Approve</p>
          </button>
          <button
            className={`bg-bloodRed w-${40} rounded text-center  h-[50px] py-3 cursor-pointer transition text-lightDark duration-300 ease-in-out hover:text-white hover:bg-opacity-80`}
            onClick={handleReject}
          >
            <p className={`text-${"white"} hover:text-white`}>Deny</p>
          </button>
          {/* <Button text={'Approve'} bg={'lightRed'} textColor='white' width={"32"} /> */}
          {/* <Button text={'Deny'} bg={'bloodRed'} textColor='white' width={"32"} /> */}
        </div>
      </div>
    </div>
  );
};

export default RaiseModal;

const List = ({ title, text }: any) => {
  return (
    <div className="flex  flex-row border border-[#C0C0C0] w-full">
      <p className="border-[#C0C0C0] px-2 py-2 flex-2 border-r w-4/12 bg-lightGray">
        {title}
      </p>
      <p className="border-[#C0C0C0] px-2 py-2 flex-2 w-8/12 text-gray">
        {text}
      </p>
    </div>
  );
};
