import React from "react";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import { AppRouter } from "./routes";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div>
      <GoogleOAuthProvider clientId="505377239968-gh5garq6jif3laf04kvijk972h8vu1e8.apps.googleusercontent.com">
        <ToastContainer />
        <AppRouter />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
