import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { getNotification, markasRead } from "../../services/api";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useGlobalContext } from "../../context/globalContext";

const NotificationModal = ({ setNotify }: any) => {
  const { user } = useGlobalContext();
  const vaultId = user?.id;
  const userdept = user?.department;

  const [data, setData] = useState<any[]>([]);

  const fetchNotifications = async () => {
    try {
      if (vaultId) {
        const res = await getNotification(vaultId);

        let msg =
          userdept === "People Operations"
            ? res?.data?.data?.data
            : res?.data?.data;

        // Sort notifications by date in descending order
        msg = msg?.sort(
          (a: any, b: any) =>
            new Date(
              b.created_at.replace("pm", " PM").replace("am", " AM")
            ).getTime() -
            new Date(
              a.created_at.replace("pm", " PM").replace("am", " AM")
            ).getTime()
        );
        setData(msg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    AOS.init();
    fetchNotifications();
  }, []);

  const handleClose = () => {
    setNotify(false);
  };

  const handleMarkAsRead = async (notificationId: any) => {
    try {
      const response = await markasRead(notificationId);

      if (response.status === 200) {
        fetchNotifications();
      }
    } catch (error) {
      console.log("error marking as read", error);
    }
  };

  const unreadNotifications = data.filter((ele) => ele.is_read !== "1");
  const readNotifications = data.filter((ele) => ele.is_read === "1");

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-[rgba(136,133,133,0.7)]"
      data-aos="fade-left"
      data-aos-duration="700"
      data-aos-easing="linear"
    >
      <div
        className="h-[94%] lg:w-[30%] shadow-md z-50 bg-white rounded-xl p-4 fixed top-1/2 right-2 transform -translate-y-1/2 cursor-auto "
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-easing="linear"
      >
        <div className="flex justify-between items-center p-2 mb-8">
          <p className="text-basetext">Notifications</p>

          <p
            className="text-lightRed bg-orange flex items-center justify-center h-8 w-8 rounded-2xl cursor-pointer"
            onClick={handleClose}
          >
            X
          </p>
        </div>
        <div className="overflow-y-scroll max-h-[84%]">
          {unreadNotifications.length > 0 && (
            <>
              <h3 className="text-lg font-semibold">New Notification(s)</h3>
              {unreadNotifications.map((ele) => (
                <div
                  key={ele.id}
                  className="bg-white flex items-center w-full rounded shadow-md p-1 my-4"
                >
                  <div
                    className="flex items-center w-full cursor-pointer rounded p-2"
                    onClick={() => handleMarkAsRead(ele.id)}
                  >
                    <div>
                      <IoMdNotificationsOutline size={30} />
                    </div>
                    <div className="ml-4 flex flex-col">
                      <p className="text-[12px]">{ele.message}</p>

                      <p className="text-[12px] mt-2">{ele.created_at}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {readNotifications.length > 0 && (
            <>
              <h3 className="text-lg font-semibold mt-8">
                Old Notification(s)
              </h3>
              {readNotifications.map((ele) => (
                <div
                  key={ele.id}
                  className="bg-lighterGray flex items-center w-full rounded shadow-md p-1 my-4"
                >
                  <div
                    className="flex items-center w-full cursor-pointer rounded p-2"
                    onClick={() => handleMarkAsRead(ele.id)}
                  >
                    <div>
                      <IoMdNotificationsOutline size={30} />
                    </div>
                    <div className="ml-4 flex flex-col">
                      <p className="text-[12px]">{ele.message}</p>

                      <p className="text-[12px] mt-2">{ele.created_at}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        {data.length === 0 && <p>No notifications to display</p>}

        {/* {data && Array.isArray(data) && data.length > 0 ? (
          data.map((ele) => (
            <div
              key={ele.id}
              className={`${
                ele.is_read === "1" ? "bg-lighterGray" : "bg-white"
              } flex items-center w-full rounded shadow-md p-1 my-4`}
            >
              <div
                className="flex items-center w-full cursor-pointer rounded p-2"
                onClick={() => handleMarkAsRead(ele.id)}
              >
                <div>
                  <IoMdNotificationsOutline size={30} />
                </div>
                <div className="ml-4 flex flex-col">
                  <p className="text-[12px]">{ele.message}</p>
                  <p className="text-[12px] mt-2">{ele.created_at}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No notifications to display</p>
        )} */}
      </div>
    </div>
  );
};

export default NotificationModal;
