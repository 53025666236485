"use client"
import React from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

// import { useRouter } from 'next/navigation'



interface DescripProp {
    maintext: string,
    subtext: string,
    subsubtext: string,
}

const HeaderDescription = ({ maintext, subtext, subsubtext }: DescripProp) => {
    const navigate = useNavigate();

    // const router = useRouter()
    
    return (
        <div className="flex flex-row items-center">
            <FaArrowLeftLong size={20} className="cursor-pointer text-black" onClick={() => navigate(-1)} />
            <div className="flex flex-row items-center">
                <p className="text-gray px-1">{maintext}</p>
                <p className="text-gray px-1">/ {subtext}</p>
                <p className="text-black px-1 font-bold">/ {subsubtext}</p>
            </div>
        </div>
    )
}

export default HeaderDescription