import { Column } from "react-table";
import React from "react";
import { people } from "../image";

const AllUsersTableHeader = (
  action: (item: any) => void
): readonly Column<any>[] => [
    {
      Header: "Name",
      accessor: 'firstname',
      //   @ts-ignore
      Cell: ({ row }: any) => {
        const { firstname, lastname, email } = row?.original;
        return (
          <div className="text-lightDark flex gap-1 items-center  rounded text-[12px]">
            {/* <p>
              <img src={people} className="w-[30px] h-[30px]" alt="oo" />
            </p> */}
            <div>
              <p>{firstname} {lastname}</p>
              <p className="text-[#6B788E]">{email}</p>
            </div>
          </div>
        );
      },
    },

    {
      Header: "Request ID",
      accessor: 'id',
      //   @ts-ignore
      Cell: ({ value }: Record<string, string>) => {
        if (value) {
          return (
            <p className="text-lightDark  text-[12px]">
              {value}
            </p>
          );
        }
        return "N/A";
      },
    },
    {
      Header: "Role",
      accessor: 'role',
      //   @ts-ignore
      Cell: ({ value }: Record<string, string>) => {
        if (value) {
          return (
            <p className="text-lightDark  text-[12px]">
              {value}
            </p>
          );
        }
        return 0;
      },
    },

    {
      Header: "Date",
      accessor: 'created',
      //   @ts-ignore
      Cell: ({ value }: Record<string, string>) => {
        if (value) {
          return (
            <p className="text-lightDark   text-[12px]">
              {value}
            </p>
          );
        }
        return "N/A";
      },
    },
    {
      Header: "Request type",
      accessor: 'type',
      //   @ts-ignore
      Cell: ({ value }: Record<string, string>) => {
        if (value) {
          return (
            <div>
              <p className="text-lightDark bg-[#F5F6F7] p-1 rounded text-[12px]">
                {value}
              </p>
            </div>

          );
        }
        return "N/A";
      },
    },

    {
      Header: "Status",
      accessor: 'status',
      //   @ts-ignore
      Cell: ({ value }: Record<string, string>) => {

        if (value) {
          return (
            <p className=" w-full text-base">
              {/* <span className={`w-10/12 p-1 rounded ${value.trim() === 'in progress' ? 'bg-[#FFF4CD] text-[#F7CE45]' : 'bg-[#ECFDF3] text-[#027A48]'}`}>
                {value}
              </span> */}

              <span className={`w-10/12 p-1 rounded ${value.trim() === 'in progress' ? 'bg-[#FFF4CD] text-[#F7CE45]' : (value.trim() === 'rejected' ? 'bg-[#DC2626] text-white' : 'bg-[#ECFDF3] text-[#027A48]')}`}>
                {value}
              </span>
            </p>
          );
        }
        return "N/A";
      },
    },

    {
      id: "action",
      accessor: (item: any) => {
        return <>{action(item)}</>;
      },
    },
  ];

export default AllUsersTableHeader;