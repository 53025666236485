import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Card from "../components/Card";
import Loader from "../components/Loader";
import Annoucement from "../components/Annoucement";
import { abstract } from "../components/image";
import { RiTeamFill } from "react-icons/ri";
import { GiUnicorn } from "react-icons/gi";
import { FaUserGear } from "react-icons/fa6";
import { RiOrganizationChart } from "react-icons/ri";
import { SiLevelsdotfyi } from "react-icons/si";
import { FaPeopleArrows } from "react-icons/fa";
import DashBoardModal from "../components/modal/DashBoardModal";
import { useGlobalContext } from "../context/globalContext";

const Dashboard = () => {
  const { token } = useGlobalContext();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openCardIndex, setOpenCardIndex] = useState(-1);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleLinkClick = (link: string, index: number) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (link.trim() === "/how-to-work/sme" || link.trim() === "/hr") {
        setModal(true);
        // window.open('https://hr.lifebankcares.com', '_blank');
      } else {
        setOpenCardIndex(index === openCardIndex ? -1 : index);
        navigate(link);
      }
    }, 1000);
  };

  return (
    <div
      className="w-full gap-2 flex flex-col md:flex-row justify-between px-4"
      style={{ backgroundImage: `url(${abstract})` }}
    >
      <div className="md:w-[72%] md:h-[11/12]">
        {modal && <DashBoardModal setModal={setModal} />}
        {loading && <Loader />}
        <Header />
        <div className="w-[99%/100%] mt-6 lg:mb-1  flex justify-center items-center px-2 mx-auto  md:h-4/6 flex-wrap gap-4 overflow-auto no-scrollbar">
          {cardDetails?.map((card: any, index: any) => {
            return (
              <div className="" key={index}>
                <Card
                  backContent={
                    <div className="flex flex-col items-center justify-between w-full h-[120px] ">
                      {/* <img src={card.icon} alt={card.title} className="w-14 h-14 " /> */}
                      <p className="w-20 h-20 flex items-center justify-center">
                        <span className="text-lightRed">{card.icon}</span>
                      </p>
                      <p className={`text-xl `}>{card.title}</p>
                    </div>
                  }
                  frontContent={
                    <ul className="flex flex-col justify-between items-center w-full">
                      {card?.links && Array.isArray(card.links) ? (
                        card.links.map(
                          (
                            link: { text: string; url: string },
                            linkIndex: React.Key | null | undefined
                          ) => (
                            <li
                              key={linkIndex}
                              onClick={() => handleLinkClick(link.url, index)}
                              className="flex justify-center items-center border-2  border-white w-full mt-1 mb-2 p-1 rounded-lg"
                            >
                              <span
                                className={`flex justify-center text-center items-center `}
                              >
                                {link.text}
                              </span>
                            </li>
                          )
                        )
                      ) : (
                        <li>No links available</li>
                      )}
                    </ul>
                  }
                  isOpen={index === openCardIndex}
                  onToggle={() =>
                    setOpenCardIndex(index === openCardIndex ? -1 : index)
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="lg:w-[28%]">
        <Annoucement />
      </div>
    </div>
  );
};

export default Dashboard;

const cardDetails = [
  {
    title: "Our Team",
    links: [
      { text: "About LifeBank", url: "/about" },
      { text: "Mission and Vision", url: "/mission" },
      { text: "Our Values", url: "/value" },
      { text: "Organisational Charts", url: "/organization" },
      { text: "Directory", url: "/directory" },
    ],
    icon: <RiTeamFill size={40} />,
  },
  {
    title: "Unicorn Geng",
    link: "/assignments",
    icon: <GiUnicorn size={40} />,
    links: [
      { text: "True North", url: "/true-north" },
      { text: "Quarterly Goals", url: "/quarterly-goals" },
      { text: "Annual Goals", url: "/annual-goals" },
    ],
  },
  {
    title: "How to Work",
    link: "/assignments",
    icon: <FaUserGear size={40} />,
    links: [
      { text: "Employee Manual", url: "/how-to-work/employee-manual" },
      { text: "Your Benefits", url: "/how-to-work/benefit" },
      { text: "Get SME", url: "/how-to-work/sme" },
    ],
  },
  {
    title: "Company System",
    link: "/coming-soon",
    icon: <RiOrganizationChart size={40} />,
    links: [
      { text: "coming soon", url: "/coming-soon" },
      // { text: 'Success System', url: '/company-system/success-system' },
      // { text: 'Fulfilment System', url: '/company-system/fulfilment-system' },
      // { text: 'Change Mangement', url: '/company-system/change-management' },
    ],
  },
  {
    title: "Hr Partners",
    link: "/sops",
    icon: <FaPeopleArrows size={40} />,
    links: [
      { text: "Request for payslip", url: "/hr" },
      { text: "Apply for leave", url: "/hr" },
      { text: "Appraise self", url: "/hr" },
      { text: "Appraise others", url: "/hr" },
      { text: "Blow whistle", url: "/hr" },
    ],
  },
  {
    title: "Growth @LifeBank",
    link: "growth",
    icon: <SiLevelsdotfyi size={40} />,
    links: [
      { text: "Give me a raise", url: "/growth/raise" },
      { text: "I don't Fit in My department", url: "/growth/fit-in" },
    ],
  },
];
