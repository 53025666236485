import React from "react";
// import { Lato } from "next/font/google";

interface ButtonProps {
  text: string;
  width?: string;
  textColor?: string;
  onClick?: () => void;
  bg: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

// const smallerLato = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const Button = ({
  text,
  type,
  textColor,
  width,
  onClick,
  bg,
  ...rest
}: ButtonProps) => {
  return (
    <div
      className={`bg-${bg} w-${width}  px-2 py-3 rounded text-center h-[50px] cursor-pointer transition text-lightDark duration-300 ease-in-out hover:text-white hover:bg-opacity-80`}
    >
      <button onClick={onClick} type={type}>
        <p className={`text-${textColor} hover:text-white`}>{text}</p>
      </button>
    </div>
  );
};
export default Button;
