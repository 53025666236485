import React, { useState } from "react";

const Card = ({ frontContent, backContent, isOpen, onToggle }: any) => {
  const handleClick = () => {
    if (typeof onToggle === "function") {
      onToggle();
    }
  };

  return (
    <div
      className={`rounded-3xl h-60 w-60 hover:bg-[#fbc2c2] ease-in-out hover:text-white  transition  bg-white  shadow-lg 
         flex-shrink-0 mt-2  cursor-pointer flex flex-col justify-center items-center z-10 `}
      style={{
        transform: isOpen ? "rotateY(360deg)" : "rotateY(0deg)",
        transition: "transform 2.9s ease-in-out",
      }}
      onClick={handleClick}
    >
      <div className="relative w-full h-full">
        <div
          className={` w-full h-full  backface-hidden flex flex-col justify-center items-center ${
            isOpen ? "transform  rotate-y-180" : ""
          }`}
        >
          {isOpen ? (
            <div className="bg-lightRed text-white w-full h-full  flex flex-col items-center justify-center rounded-3xl border-2 border-white p-4">
              {frontContent}
            </div>
          ) : (
            <div className="text-center text-lightDark">{backContent}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
