import React from "react";
import { GoArrowRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
// import { Lato } from "next/font/google";

// const LatoFont = Lato({
//     subsets: ['latin'],
//     weight: ["700"],
// });

// const smallerLato = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

interface ButtonProps {
  text: string;
  onClick: () => void;
  subText?: string;
}

interface button extends ButtonProps {
  icon?: any;
  bg: string;
}

const FowardButton = ({ text, onClick, subText }: ButtonProps) => {
  return (
    <div
      className="flex flex-row shadow-2xl lg:w-[250px] rounded-lg items-center justify-around cursor-pointer p-2 hover:bg-[#fbc2c2] transition-all duration-300"
      onClick={onClick}
    >
      <div className="flex flex-col  justify-center">
        <p className={` text-black text-basetext`}>{text}</p>
        <p className="text-black">{subText}</p>
      </div>
      <div>
        <GoArrowRight size={30} />
      </div>
    </div>
  );
};

const BackButton = ({ text, onClick, subText }: ButtonProps) => {
  return (
    <div
      className="flex flex-row shadow-2xl lg:w-[250px] rounded-lg items-center justify-between cursor-pointer p-2 hover:bg-[#fbc2c2] transition-all duration-300"
      onClick={onClick}
    >
      <div>
        <GoArrowLeft size={30} color={"black"} />
      </div>
      <div className="flex flex-col  justify-between">
        <p className={` text-black text-basetext text-right`}>{text}</p>
        <p className="text-black text-right">{subText}</p>
      </div>
    </div>
  );
};

const Button = ({ text, icon, bg, onClick }: button) => {
  return (
    <div
      className={` flex items-center justify-center rounded shadow-lg bg-${bg} p-2 cursor-pointer hover:bg-[#ba5a5a] transition-all duration-300`}
      onClick={onClick}
    >
      <div className="p-1">{icon}</div>
      <div className={`p-1 ${bg === "bloodRed" && "text-white"}`}>{text}</div>
    </div>
  );
};

export { FowardButton, BackButton, Button };
