import { useMutation } from "@tanstack/react-query";
import { login } from "../services/api";
import { AxiosResponse } from "axios";
import { saveTokenToCookies, saveAuthUser } from "../helper";
import { toast } from "react-toastify";
import { useGlobalContext } from "../context/globalContext";

export const useSignInUser = () => {
  const { setToken } = useGlobalContext();
  const {
    isPending,
    mutate: signInUserMutate,
    error,
    isError,
    isSuccess,
    data,
  } = useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: (payload: any) => login(payload),
    mutationKey: ["signInUser"],
    onSuccess(data: AxiosResponse) {
      saveTokenToCookies(data?.data?.token);
      setToken(data?.data?.token);
      const user = data?.data?.data;

      const fieldsToExtract = [
        "name",
        "email",
        "department",
        "picture",
        "id",
        "firstname",
      ];

      const userDetails = fieldsToExtract.reduce((obj, field) => {
        obj[field] = user[field];
        return obj;
      }, {} as { [key: string]: string });

      const userDetailsArray = userDetails;

      saveAuthUser(userDetailsArray);
    },
    onError: (error: AxiosResponse) => {
      console.log(error);
      toast.error("Account not registered, please register through the HR.");
    },
  });
  return {
    isPending,
    signInUserMutate,
    error,
    isError,
    isSuccess,
    data,
  };
};
