import React, { useState } from "react";
import { GoDot } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { lifebanklogo } from "../components/image";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/globalContext";

import { GrUserAdmin } from "react-icons/gr";
import { RiTeamFill } from "react-icons/ri";
import { GiUnicorn } from "react-icons/gi";
import { FaUserGear } from "react-icons/fa6";
import { RiOrganizationChart } from "react-icons/ri";
import { SiLevelsdotfyi } from "react-icons/si";
import { FaPeopleArrows } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import DashBoardModal from "../components/modal/DashBoardModal";

interface dataProps {
  link: string;
  text: string;
  image: string | any;
  subLink?: { text: string; link: string }[];
}

const data = [
  {
    link: "/dashboard",
    text: "Dashboard",
    image: <MdOutlineDashboardCustomize color="white" />,
  },
  // {
  //     link: '/hr',
  //     text: 'Admin',
  //     image: <GrUserAdmin color="white"/>,
  //     subLink: [
  //         {
  //             text: "Request",
  //             link: "/reports"
  //         },
  //         {
  //             text: "Add employee",
  //             link: '/directory',
  //         },
  //         {
  //             text: "Create Announcement",
  //             link: "/admin/create-announcement"
  //         },
  //         {
  //             text: "Update employee of month",
  //             link: "/admin/employee-month"
  //         }
  //     ]
  // },
  {
    link: "/team",
    text: "Our Team",
    image: <RiTeamFill color="white" />,
    subLink: [
      {
        text: "About LifeBank",
        link: "/about",
      },
      {
        text: "Mission and Vision",
        link: "/mission",
      },
      {
        text: "Our values",
        link: "/value",
      },

      {
        text: "Organization charts",
        link: "/organization",
      },
      {
        text: "Directory",
        link: "/directory",
      },
    ],
  },
  {
    link: "/unicorn",
    text: "Unicorn Geng",
    image: <GiUnicorn color="white" />,
    subLink: [
      {
        text: "True North",
        link: "/true-north",
      },
      {
        text: "Quarterly Goals",
        link: "/quarterly-goals",
      },
      {
        text: "Annual Goals",
        link: "/annual-goals",
      },
    ],
  },
  {
    text: "How to Work",
    link: "/assignments",
    image: <FaUserGear color="white" />,

    subLink: [
      {
        text: "Employee Manual",
        link: "/how-to-work/employee-manual",
      },
      {
        text: "Your Benefits",
        link: "/how-to-work/benefit",
      },
      {
        text: "Get SME",
        link: "/how-to-work/sme",
      },
    ],
  },
  {
    link: "/company-systems",
    text: "Company systems",
    image: <RiOrganizationChart color="white" />,
    subLink: [
      {
        text: "coming soon",
        link: "/coming-soon",
      },
    ],
  },
  {
    text: "HR Partners",
    link: "/sops",
    image: <FaPeopleArrows color="white" />,
    subLink: [
      { text: "Request for payslip -SME", link: "/hr" },
      { text: "Apply for leave -SME", link: "/hr" },
      { text: "Appraise self -SME", link: "/hr" },
      { text: "Appraise others -SME", link: "/hr" },
      { text: "Blow whistle -SME", link: "/hr" },
    ],
  },
  {
    link: "/growth",
    text: "Growth",
    image: <SiLevelsdotfyi color="white" />,
    subLink: [
      {
        text: "Apply for Raise",
        link: "/growth/raise",
      },
      {
        text: "I don't fit in",
        link: "/growth/fit-in",
      },
    ],
  },
];

const SideBar = ({ handleMobileNav }: { handleMobileNav?: () => void }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const { user } = useGlobalContext();
  const dept = user?.department;

  const handleParentItemClick = (link: string) => {
    if (link.trim() === "/how-to-work/sme" || link.trim() === "/hr") {
      setModal(true);
      // window.open('https://hr.lifebankcares.com', '_blank');
    } else {
      navigate(link);
    }
  };

  const handleChildItemClick = (link: string) => {
    if (link.trim() === "/how-to-work/sme" || link.trim() === "/hr") {
      setModal(true);
      // window.open('https://hr.lifebankcares.com', '_blank');
    } else {
      navigate(link);
    }
  };

  const handleItemClick = (index: any, link: string, hasSubLinks: boolean) => {
    if (!hasSubLinks) {
      handleParentItemClick(link);
      handleMobileNav && handleMobileNav();
    } else {
      if (expandedMenu === index) {
        setExpandedMenu(null);
      } else {
        setExpandedMenu(index);
      }
    }
  };

  const [expandedMenu, setExpandedMenu] = useState(null);

  const sidebarData = [...data];
  if (dept === "People Operations") {
    const dashboardIndex = sidebarData?.findIndex(
      (item) => item.link === "/dashboard"
    );
    if (dashboardIndex !== -1) {
      sidebarData?.splice(dashboardIndex + 1, 0, {
        link: "/hr",
        text: "Admin",
        image: <GrUserAdmin color="white" />,
        subLink: [
          {
            text: "Request",
            link: "/reports",
          },
          {
            text: "Add employee",
            link: "/directory",
          },
          {
            text: "Create Announcement",
            link: "/admin/create-announcement",
          },
          {
            text: "Update employee of month",
            link: "/admin/employee-month",
          },
        ],
      });
    }
  }

  return (
    <div className="w-full bg-bloodRed h-screen overflow-y-auto">
      {modal && <DashBoardModal setModal={setModal} />}
      <div className="px-6 h-12 flex  mx-auto items-center mb-4">
        <img
          src={lifebanklogo}
          alt="logo"
          className="text-white w-[96px] cursor-pointer"
        />
      </div>
      {sidebarData?.map((ele: dataProps, index) => (
        <div key={ele.link} className="cursor-pointer">
          <div
            onClick={() => handleItemClick(index, ele.link, !!ele.subLink)}
            className={`w-full hover:bg-[#9b271f] hover:w-11/12 hover:rounded hover:elevation-5  }`}
          >
            <div className="flex flex-row gap-2 items-center  py-4 px-6">
              {/* <img src={ele.image} alt={ele.text} width={16} height={16} className="text-white " /> */}
              <p>{ele.image}</p>
              <div className={`text-white text-sm flex ml-2 `}>
                {ele.text}{" "}
                {ele.subLink && (
                  <p className="mr-4">
                    <IoMdArrowDropdown size={20} />
                  </p>
                )}
              </div>
            </div>
          </div>
          {expandedMenu === index && ele.subLink && (
            <div className="pl-8 cursor-pointer">
              {ele?.subLink?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  onClick={() => handleChildItemClick(subItem.link)}
                  className="hover:bg-[#9b271f] hover:w-11/12 hover:rounded hover:elevation-5 p-2 cursor-pointer flex flex-row items-center"
                >
                  <span className="text-white">
                    <GoDot />
                  </span>
                  <span className={`text-white text-sm ml-2 `}>
                    {subItem.text}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SideBar;
