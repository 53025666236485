import React from "react";

interface ErrorMessageProps {
    error: string | undefined | null; 
    visible: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, visible }) => {
    if (!visible || !error) return null;

    return <div className="text-red-700 text-sm">{error}</div>;
};

export default ErrorMessage;
