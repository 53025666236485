import { HiDotsHorizontal } from "react-icons/hi";

const Actions = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div className="flex flex-row space-x-3 text-sm cursor-pointer">
      <HiDotsHorizontal onClick={handleClick} />
    </div>
  );
};

export default Actions;
