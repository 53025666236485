import React, { ReactNode } from 'react'
import { abstract } from './image';

interface ScreenProps {
    children: ReactNode;
}


const Screen: React.FC<ScreenProps> = ({ children }) => {
    return (
        <div className="  h-[100%] overflow-auto scrollbar-hide"
            style={{ backgroundImage: `url(${abstract})` }}
        >
            <div className="ml-3 mr-3 p-1">
                {children}

            </div>

        </div>
    )
}

export default Screen;