"use client"
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import AOS from "aos";
import "aos/dist/aos.css";

import Screen from '../../components/Screen';
import ProfileHeaderSection from '../../components/ProfileHeaderSection';
import HeaderDescription from '../../components/HeaderDescription';
import HeaderBox from '../../components/Box/HeaderBox';
import { coms, EDBoat, newDirectorPeople } from '../../components/image';

import { BackButton, FowardButton } from '../../components/forms/DashboardButton';




const Comms = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("/finance")
    }
    const handlePrev = () => {
        navigate('/organization')
    }



    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="w-full h-screen">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'Director of people'} />

                <div>
                    <p className={`text-lightDark text-3xl mt-2 mb-2 `}>Organization Chart</p>
                </div>
                <div className="">
                    <HeaderBox text="A Visual guide into Lifebank structure and hierarchy" />
                </div>

                <div className="mt-6 w-[90%] h-[30%] flex items-center justify-center mx-auto"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                >
                    <img src={coms} alt="cto" className=" object-cover" />
                </div>

                <div className="my-2 flex bottom-2 gap-4 w-full justify-end">
                    <BackButton text={'Previous'} onClick={handlePrev} subText={'Organization chart'} />
                    <FowardButton text={'Next'} onClick={handleNext} subText={'Finance Chart'} />
                </div>
            </Screen>
        </div>
    )
}

export default Comms;


