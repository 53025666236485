import { HiDotsHorizontal } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

const Actions = ({
  handleClick,
  handleDelete,
}: {
  handleClick?: () => void;
  handleDelete: () => void;
}) => {
  return (
    <div className="flex flex-row space-x-3 text-sm cursor-pointer">
      {/* <FaEdit className="w-5 h-5 text-blue-400" onClick={handleClick} /> */}
      <MdDeleteForever className="w-5 h-5 text-red-600" onClick={handleDelete} />
    </div>
  );
};

export default Actions;
