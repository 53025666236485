"use client"
import React from 'react';
import Screen from '../../components/Screen';
import ProfileHeaderSection from '../../components/ProfileHeaderSection';
import HeaderDescription from '../../components/HeaderDescription';
import HeaderBox from '../../components/Box/HeaderBox';
import { BackButton, FowardButton } from '../../components/forms/DashboardButton';
import { useNavigate } from 'react-router-dom';

// import { Lato } from "next/font/google";
// import { aboutImage } from '../image';
// // import Image from ''

// import HeaderDescription from '../components/HeaderDescription';
// import ProfileHeaderSection from '../components/ProfileHeaderSection';
// import { FowardButton, BackButton } from '../components/forms/DashboardButton';
// import Screen from '../components/Screen';
// import ProfileHeaderSection from '../components/ProfileHeaderSection';
// import HeaderDescription from '../components/HeaderDescription';
// import HeaderBox from '../components/Box/HeaderBox';
// import { aboutImage } from '../components/image';
// import HeaderBox from '../components/Box/HeaderBox';
// import Image from 'next/image';
// import Screen from '../components/Screen';


// const LatoFont = Lato({
//     subsets: ['latin'],
//     weight: ["700"],
// });

// const LatoFonts = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const Benefits = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        navigate('/how-to-work/employee-manual')
    }
    const handleReadMore = () =>{
        window.open('https://drive.google.com/file/d/1-ue_5t3CXqPqvYzj1LJ5xrOvQuHWL-df/view?usp=drive_link', '_blank');

    }

    return (
        <div className="bg-white w-full">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'Benefit'} />
                <p className={` text-4xl text-lightDark my-4`}>Benefit</p>
                <div className="">
                    <HeaderBox text="Revolutionizing African healthcare through technology" />
                </div>
                <div className={` text-black ml-2 mt-4 text-basetext leading-10 text-left`}>
                A calm high performing environment where you can do good, learn fast, do well!
                    Service - We don’t sell to our customers, we seek to serve them first. How can we make life easier for the thousands of health workers who stay back in Africa DSC Cv  to make sure Africa is in good health? This is the main question that every LifeBanker should ask themselves everyday. How can I serve our amazing health workers and hospitals? How can I use code to improve their work? How can I use persuasion to ensure they know that LifeBank is the easiest way to get what they need? How can I deliver reliably, quickly, and well for them? How do I tell their stories in the way that will make them proud? 
                </div>
               
                <div className="my-4 flex justify-end absolute bottom-2 right-3">
                    <BackButton text={'Previous'} onClick={handleNext} subText={'Employee manual'} />
                </div>
            </Screen>
        </div >
    )
}

export default Benefits;