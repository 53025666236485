/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";
import React, { useState, useMemo, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Screen from "../components/Screen";
import ProfileHeaderSection from "../components/ProfileHeaderSection";
import {
  BackButton,
  Button,
  FowardButton,
} from "../components/forms/DashboardButton";
import { CiExport } from "react-icons/ci";
import { BsPlusSquareDotted } from "react-icons/bs";
import ContactCard from "../components/Box/ContactCard";
// import { people } from "../image";
import { IoSearchOutline } from "react-icons/io5";
import { IoFilterOutline } from "react-icons/io5";
// import { Lato } from "next/font/google";
// import Modal from '../components/AddEmployee/page';
// import { list, box } from "../../app/image";

import TableCheckbox from "../components/TableRequest/TableCheckbox";
// import { Button } from '@mui/material';
import TableLayout from "../components/TableRequest/TableLayout";
import TablePagination from "../components/TableRequest/TablePagination";
import TableHeader from "../components/TableRequest/TableColumn";
import TableAction from "../components/TableRequest/TableAction";
import DataTableCard from "../components/TableRequest/DataTableCard";
import Modal from "../components/modal/RaiseModal";
import { box, list, people } from "../components/image";
import TableSearch from "../components/TableRequest/TableSearch";
import Actions from "../components/TableRequest/TableAction";
import { allRequest } from "../services/api";
// import Image from 'next/image';

// const LatoFonts = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const Report = () => {
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState();
  const [data, setData] = useState([]);

  const handleModal = (item: any) => {
    setDetails(item);
    setShowModal(true);
  };

  const columns: any = useMemo(
    () =>
      TableHeader((item) => <Actions handleClick={() => handleModal(item)} />),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    state,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    nextPage, // @ts-ingnore
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    selectedFlatRows,
    // @ts-ignore
    state: { selectedRowIds },
    // @ts-ignore
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  // @ts-ignore
  const { globalFilter, pageIndex, pageSize } = state;

  const fetch = async () => {
    try {
      const response = await allRequest();
      // @ts-ignore
      if (response?.data == null || response?.success === false) {
        setData([]);
      } else {
        const data = response?.data?.data.reverse();
        setData(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value - 1);
  };

  return (
    <div className="bg-white w-full">
      {showModal && <Modal details={details} setShowModal={setShowModal} />}
      <Screen>
        <ProfileHeaderSection />
        <div className=" border border-[#C0C0C0] w-full lg:mt-2 mt-14 mb-2" />
        <div className="mt-4 flex flex-row justify-between items-center">
          <p className={`text-lightDark text-xl`}> All Request</p>
        </div>
        <div className=" border border-[#C0C0C0] w-full mt-2 mb-2" />

        <div className="rounded-md border border-[#C0C0C0] py-2 flex flex-row justify-between px-2 mb-4">
          <div className="mb-2 mt-2 flex items-center py-2 rounded-md border-[#C0C0C0] w-[300px] px-2">
            <TableSearch
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder={"Employer name/role"}
            />
          </div>

          <div className="flex flex-row items-center justify-center"></div>
        </div>

        <div>
          <DataTableCard>
            <div className="overflow-x-auto w-full">
              <TableLayout
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                getTableBodyProps={getTableBodyProps}
                retrieveData={data}
                page={page}
                prepareRow={prepareRow}
                state={{
                  selectedRowIds: undefined,
                }}
              />
            </div>

            <TablePagination
              gotoPage={gotoPage}
              onPageChange={handleChange}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
            />
          </DataTableCard>
        </div>
      </Screen>
    </div>
  );
};

export default Report;
