"use client";
import React from "react";
// import { Lato } from "next/font/google";
// import { aboutImage } from '../image';
// import Image from ''

import HeaderDescription from "../components/HeaderDescription";
import ProfileHeaderSection from "../components/ProfileHeaderSection";
import { BackButton } from "../components/forms/DashboardButton";
import HeaderBox from "../components/Box/HeaderBox";
// import Image from 'next/image';
import Screen from "../components/Screen";
import { annual } from "../components/image";
import { useNavigate } from "react-router-dom";

// const LatoFont = Lato({
//     subsets: ['latin'],
//     weight: ["700"],
// });

// const LatoFonts = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const Annual = () => {
  const navigate = useNavigate();

  const handlePrev = () => {
    navigate("/quarterly-goals");
  };

  return (
    <div className="bg-white w-full">
      <Screen>
        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Dashboard"}
          subtext={"Our Team"}
          subsubtext={"About Annual goals"}
        />
        <p className={` text-4xl text-lightDark my-4`}>About Annual goals</p>
        <div className="">
          <HeaderBox text="Our Annual goals" />
        </div>
        <div className={` text-black  mt-4 text-basetext leading-7 text-left`}>
          Here are our goals for 2024. We can meet our goals if we all work hard
          and give our best! {`Let's`} GO!
        </div>

        {/* <div className="w-[70%] mx-auto flex justify-center"> */}
        <div className="w-full flex flex-col lg:flex-row justify-between lg:h-96 rounded mt-4 bg-orange gap-2">
          <div className="lg:w-6/12  px-2 py-4 h-5/6 flex flex-col justify-center ">
            <p className={`text-black text-3xl mb-4 `}>
              The <span className={`text-lightRed text-2xl `}>Market</span>
            </p>
            <div>
              <p className={`text-black  text-basetext leading-7`}>
                LifeBank is on a mission to save lives across Africa by
                delivering critical life-saving supplies like blood, oxygen,
                medical samples and consumables, PPE and rare medicines to
                hospitals in Africa using technology and a multi-modal
                distribution platform
              </p>
            </div>
          </div>
          <div className="lg:w-6/12  p-2  items-center flex flex-col justify-center">
            <img src={annual} alt="north" className="max-h-64" />
          </div>
        </div>

        <div className="my-4 flex justify-end">
          <BackButton
            text={"Previous"}
            onClick={handlePrev}
            subText={"Quarterly Goals"}
          />
          {/* <FowardButton text={'Next'} onClick={handleNext} subText={'Quarterly goals'} /> */}
        </div>
        {/* <div className="my-4 flex justify-end">
                    <FowardButton text={'Next'} onClick={handleNext} subText={'Mission & Vision'} />
                </div> */}
      </Screen>
    </div>
  );
};

export default Annual;
