import React, { ReactNode, useEffect } from "react";
import SideBar from "./Sidebar";
import { useGlobalContext } from "../context/globalContext";

type Props = {
  children:
    | string
    | JSX.Element
    | JSX.Element[]
    | (() => JSX.Element)
    | ReactNode
    | any;
};

const MainLayout = ({ children }: Props) => {
  const { token } = useGlobalContext();
  useEffect(() => {
    if (!token) {
      window.location.href = "/sign-in";
    }
  }, [token]);
  return (
    <div className="flex w-full h-[100%] fixed top-0 bottom-0 left-0 right-0">
      <div className="w-[18%] h-[100%] hidden lg:block">
        <SideBar />
      </div>
      <div className="bg-white lg:w-[82%]  h-[100%] overflow-y-auto flex">
        {children}
      </div>
    </div>
  );
};
export default MainLayout;
