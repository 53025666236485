/* eslint-disable consistent-return */
import Cookies from "js-cookie";

const accessToken = "vault";
const userAuth = "user";

// Save the token to cookies after a successful login or wherever you receive the token
const saveTokenToCookies = (token: string) => {
  Cookies.set(accessToken, token, { expires: 7 }); // You can set the expiration period as needed
};

// Get the token from cookies
// To retrieve the token when making API requests
const getAccessTokenFromCookies = (): string | undefined => {
  return Cookies.get(accessToken);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveAuthUser = (user: any) => {
  Cookies.set(userAuth, JSON.stringify(user));
};

const getAuthUser = () => {
  const token = getAccessTokenFromCookies();
  if (token) {
    const authUser = Cookies.get(userAuth);
    return authUser ? JSON.parse(authUser) : deleteAuthUser();
  }
};

const deleteAuthUser = () => {
  Cookies.remove(userAuth);
  Cookies.remove(accessToken);
};

const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const dateTimeFormatter = (value: string) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = (
    "0" +
    date
      .getDate()
      .toString()
      .replace(/^(\d)$/, "0$1")
  ).slice(-2);

  const hour = ("0" + date.getHours()).slice(-2);
  const min = ("0" + date.getMinutes()).slice(-2);
  const sec = ("0" + date.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
};

const removeUnderscore = (value: string) => {
  return value.replace(/_/g, " ");
};

const phonePattern = (length: undefined | number | string) => {
  if (typeof length === "number" && length > 0) {
    return new RegExp(`^[0-9]{${length}}$`);
  } else {
    return /^[0-9]+$/; // Accept any number of digits
  }
};

const getAllPositions = () => {
  return [
    {
      id: "organisation",
      account_type: "Organisation",
    },
    {
      id: "admin",
      account_type: "Organisation",
    },
    {
      id: "user",
      account_type: "Organisation",
    },
    {
      id: "individual",
      account_type: "User",
    },
    // active waybill
    {
      id: "active",
      name: "Organisation",
    },
  ];
};

const getPrivileges = () => {
  const user = getAuthUser();
  if (!user) return {};

  const checkAdminPermission = (privileges: string | any[]): boolean => {
    return (
      privileges.includes(user.account_type.toLowerCase()) &&
      privileges.includes(user.user_type.toLowerCase())
    );
  };

  const checkPermission = (privileges: string | any[]): boolean => {
    if (user?.organization && user?.organization?.services?.waybill) {
      return (
        privileges.includes(user.account_type.toLowerCase()) &&
        privileges.includes(user.user_type.toLowerCase()) &&
        privileges.includes(user?.organization?.services?.waybill)
      );
    }
    return (
      privileges.includes(user.account_type.toLowerCase()) &&
      privileges.includes(user.user_type.toLowerCase())
    );
  };

  const checkWaybillPermission = (privileges: string | any[]): boolean => {
    return (
      privileges.includes(user.account_type.toLowerCase()) &&
      privileges.includes(user.user_type.toLowerCase()) &&
      privileges.includes(user?.organization?.services?.waybill)
    );
  };

  const checkAddressPermission = (privileges: string | any[]): boolean =>
    privileges.includes(user.account_type.toLowerCase()) &&
    privileges.includes(user.user_type.toLowerCase()) &&
    privileges.includes(user?.organization?.services?.verification);

  const allPositions = getAllPositions().map((p) => p.id);

  return {
    viewDashboard: checkPermission([...allPositions, "rejected"]),
    viewDepartment: checkPermission([
      "admin",
      "organisation",
      "rejected",
      "active",
    ]),
    manageUsers: checkPermission([
      "admin",
      "organisation",
      "rejected",
      "active",
    ]),
    shipment: checkPermission(allPositions),
    wallet: checkPermission([...allPositions, "rejected"]),
    bulk: checkPermission(allPositions),
    tracking: checkPermission(allPositions),
    printWaybill: checkPermission(allPositions),
    addressVerification: checkAddressPermission([
      "admin",
      "organisation",
      "user",
      "active",
    ]),
    settings: checkPermission(allPositions),
    isUser: checkPermission(["individual", "user"]),
    isAdmin: checkAdminPermission(["admin", "organisation", "user"]),
    isUserOrganization: checkPermission(["organisation", "user"]),
  };
};

export {
  saveTokenToCookies,
  getAccessTokenFromCookies,
  saveAuthUser,
  getAuthUser,
  deleteAuthUser,
  dataURItoBlob,
  dateTimeFormatter,
  removeUnderscore,
  phonePattern,
  getAllPositions,
  getPrivileges,
};
