import React, { useRef, useState } from "react";
import AppForm from "../forms/AppForm";
import { AppField } from "../forms/Field";
import { SelectField } from "../forms/SelectField";
import Button from "../../components/forms/Button";
import * as Yup from "yup";
import DatepickerField from "../forms/DatePicker";
import axios from "axios";
import LoaderComponent from "../LoadingComponent";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../context/globalContext";
import { useRegisterEmployee } from "../../hooks/useEmployee";
import TransitionsModal from "../../components/modal/CustomModal";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required().label("First Name"),
  lastname: Yup.string().required().label("Last Name"),
  email: Yup.string().required().label("Email"),
  department: Yup.string().required().label("Department"),
  job_role: Yup.string().required().label("Job Role"),
  datejoined: Yup.string().required().label("Date Joined"),
  city: Yup.string().required().label("City"),
  country: Yup.string().required().label("Country"),
  employment_type: Yup.string().required().label("Employment Type"),
  phone: Yup.string().required().label("Phone"),
  lead: Yup.string().required().label("Lead"),
});

interface Props {
  closeModal: () => void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
}

const Modal = ({ closeModal, setShowModal, showModal }: Props) => {
  const { token } = useGlobalContext();
  const { mutateRegisterEmployee, isPending } = useRegisterEmployee();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fieldError, setFieldError] = useState<string>(""); // State to manage image validation error
  const [loading, setLoading] = useState<boolean>(false);

  function formatDate(inputDateString: string | number | Date) {
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleClose = () => {
    closeModal();
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setFieldError(""); // Clear the error message when a file is selected
    }
  };

  const handleSubmit = async (values: any) => {
    if (!selectedFile) {
      setFieldError("Image is required");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("firstname", values.firstname);
    formData.append("lastname", values.lastname);
    formData.append("email", values.email);
    formData.append("department", values.department);
    formData.append("job_role", values.job_role);
    formData.append("datejoined", formatDate(values.datejoined));
    formData.append("city", values.city);
    formData.append("country", values.country);
    formData.append("phone", values.phone);
    formData.append("employment_type", values.employment_type);
    formData.append("lead", values.lead);

    mutateRegisterEmployee(formData, {
      onSuccess: (data) => {
        if (data) {
          console.log(data);
          toast.success("Employee successfully added!");
          closeModal();
        }
      },
      onError: (error) => {
        console.error("Error during form submission:", error);
        toast.error("Error submitting the form");
      },
    });

    // try {
    //   setLoading(true);
    //   const response = await axios.post(
    //     "https://vault.lifebanktest.com/api/v1/auth/register",
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );

    //   setLoading(false);

    //   if (response.data && response.data.message) {
    //     if (
    //       response.data.message ===
    //       "User with the provided email already exists"
    //     ) {
    //       toast.error("User with the provided email already exists");
    //     } else {
    //       toast.success("Employee successfully added!");
    //       closeModal();
    //     }
    //   }
    // } catch (error) {
    //   console.error("Error during form submission:", error);
    //   setLoading(false);
    //   toast.error("Error submitting the form");
    // }
  };

  return (
    <>
      <TransitionsModal
        open={showModal}
        setOpen={setShowModal}
        title={"Add Employee"}
        handleCloseModal={closeModal}
      >
        {" "}
        <AppForm
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            department: "",
            datejoined: "",
            dob: "",
            city: "",
            country: "",
            employment_type: "",
            phone: "",
            lead: "",
            image: "",
          }}
          onSubmit={(values: any) => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          <div className="flex flex-col  md:flex-row gap-8 justify-between mt-8">
            <div className="w-full">
              <AppField
                name={"firstname"}
                label="First Name"
                placeholder="John Doe"
              />
            </div>
            <div className=" w-full">
              <AppField
                name={"lastname"}
                label="Last Name"
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="flex flex-col  md:flex-row gap-8 w-full justify-between items-center mt-4">
            <div className="w-full">
              <AppField
                name={"email"}
                label="Email"
                placeholder="johnDoe@gmail.com"
              />
            </div>
            <div className="w-full">
              <AppField
                name={"phone"}
                label="Employee Phone"
                placeholder="0933798721"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-8 w-full justify-between items-center mt-4">
            <div className="w-full">
              <SelectField
                name={"department"}
                label="Department"
                options={departmentOptions}
                placeholder="Select Department"
              />
            </div>
            <div className="w-full">
              <AppField
                name={"job_role"}
                label="Job role"
                placeholder="Add role"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-8 w-full justify-between items-center mt-4">
            <div className="w-full">
              <SelectField
                name={"country"}
                label="Country"
                options={countries}
                placeholder="Select Country"
              />
            </div>
            <div className="w-full">
              <AppField name={"city"} label="City" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-8 w-full justify-between items-center mt-4">
            <div className="w-full">
              <SelectField
                name={"employment_type"}
                label="Type of Employment"
                options={contractType}
                placeholder="Select type of employment"
              />
            </div>
            <div className="w-full">
              <AppField
                name={"lead"}
                label="Employee Lead"
                placeholder="Employee lead"
              />
            </div>
          </div>
          <div className="flex flex-col  md:flex-row gap-8 w-full justify-between items-center mt-4">
            <div className="w-full lg:px-0">
              <DatepickerField name={"datejoined"} label="Date Joined" />
            </div>
            <div className="w-full flex justify-center flex-col item-center">
              <p className="mb-2">Upload Image (jpg, jpeg)</p>
              <div className="justify-center rounded p-2 bg-[#fcfcfc]">
                <input
                  type="file"
                  accept=".jpg, .jpeg"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  className="rounded w-full"
                />
                {fieldError && <p className="text-red-500">{fieldError}</p>}
              </div>
            </div>
          </div>
          <div className="mt-8 flex mx-auto items-center justify-center">
            <div className="w-8/12 flex mx-auto items-center justify-center">
              <Button
                type="submit"
                textColor="white"
                width="60"
                text={"New Employee"}
                bg={"lightRed"}
              />
            </div>
          </div>
        </AppForm>
      </TransitionsModal>{" "}
      {/* <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-40 bg-[rgba(136,133,133,0.7)]">
        <div className="lg:h-4/5 lg:w-[50%] shadow-md z-50 bg-white rounded-xl p-4 overflow-scroll overflow-x-hidden no-scrollbar">
          <LoaderComponent loading={loading} />
          <div className="flex justify-between">
            <p className="text-black text-[20px]">Add Employee</p>
            <p
              className="text-lightRed bg-orange flex items-center justify-center h-8 w-8 rounded-2xl cursor-pointer"
              onClick={handleClose}
            >
              X
            </p>
          </div>
          <div className="overflow-scroll">
            <AppForm
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                department: "",
                datejoined: "",
                dob: "",
                city: "",
                country: "",
                employment_type: "",
                phone: "",
                lead: "",
                image: "",
              }}
              onSubmit={(values: any) => {
                handleSubmit(values);
              }}
              validationSchema={validationSchema}
            >
              <div className="flex gap-8 w-full justify-between mt-8">
                <span className="w-6/12">
                  <AppField
                    name={"firstname"}
                    label="First Name"
                    placeholder="John Doe"
                  />
                </span>
                <span className="w-6/12">
                  <AppField
                    name={"lastname"}
                    label="Last Name"
                    placeholder="Jane Doe"
                  />
                </span>
              </div>
              <div className="flex gap-8 w-full justify-between items-center mt-4">
                <span className="w-6/12">
                  <AppField
                    name={"email"}
                    label="Email"
                    placeholder="johnDoe@gmail.com"
                  />
                </span>
                <span className="w-6/12">
                  <AppField
                    name={"phone"}
                    label="Employee Phone"
                    placeholder="0933798721"
                  />
                </span>
              </div>
              <div className="flex gap-8 w-full justify-between items-center mt-4">
                <span className="w-6/12">
                  <SelectField
                    name={"department"}
                    label="Department"
                    options={departmentOptions}
                    placeholder="Select Department"
                  />
                </span>
                <span className="w-6/12">
                  <AppField
                    name={"job_role"}
                    label="Job role"
                    placeholder="Add role"
                  />
                </span>
              </div>
              <div className="flex gap-8 w-full justify-between items-center mt-4">
                <span className="w-6/12">
                  <SelectField
                    name={"country"}
                    label="Country"
                    options={countries}
                    placeholder="Select Country"
                  />
                </span>
                <span className="w-6/12">
                  <AppField name={"city"} label="City" />
                </span>
              </div>
              <div className="flex gap-8 w-full justify-between items-center mt-4">
                <span className="w-6/12">
                  <SelectField
                    name={"employment_type"}
                    label="Type of Employment"
                    options={contractType}
                    placeholder="Select type of employment"
                  />
                </span>
                <span className="w-6/12">
                  <AppField
                    name={"lead"}
                    label="Employee Lead"
                    placeholder="Employee lead"
                  />
                </span>
              </div>
              <div className="flex gap-8 w-full justify-between items-center mt-4">
                <span className="w-6/12">
                  <DatepickerField name={"datejoined"} label="Date Joined" />
                </span>
                <div className="w-6/12 mx-auto flex flex-col item-center">
                  <p className="mb-2">Upload Image (jpg, jpeg)</p>
                  <div className="justify-center rounded p-2 bg-[#fcfcfc]">
                    <input
                      type="file"
                      accept=".jpg, .jpeg"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                      className="rounded"
                    />
                    {fieldError && <p className="text-red-500">{fieldError}</p>}
                  </div>
                </div>
              </div>
              <div className="mt-8 flex mx-auto items-center justify-center">
                <div className="w-8/12 flex mx-auto items-center justify-center">
                  <Button
                    type="submit"
                    textColor="white"
                    width="60"
                    text={"New Employee"}
                    bg={"lightRed"}
                  />
                </div>
              </div>
            </AppForm>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Modal;

const countries = [
  { value: "Nigeria", label: "Nigeria" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Kenya", label: "Kenya" },
];

const departmentOptions = [
  { value: "People Operations", label: "People Operations" },
  { value: "Engineering", label: "Engineering" },
  { value: "Finance", label: "Finance" },
  { value: "Boat", label: "Boat" },
  { value: "Sales", label: "Sales" },
  { value: "Communications & Marketing", label: "Communications & Marketing" },
  { value: "Customer Success", label: "Customer Success" },
  { value: "Operations", label: "Operations" },
  { value: "Innovation", label: "Innovation" },
];

const contractType = [
  { value: "contract", label: "contract" },
  { value: "Internship", label: "internship" },
  { value: "Full-time", label: "full-time" },
];
