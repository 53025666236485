import client from "../axiosConfig/axios";

const login = (data: string) => {
  return client.post("/auth/sign-in", data);
};

const registerEmployee = (data: any) => {
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return client?.post(`/auth/register`, data, options);
};

const deleteEmployee = (data: any) => {
  return client?.delete(`/app/users/${data}`);
};

const editEmployee = (data: any) => {
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return client?.patch(`/auth/edit-user/${data.id}`, data, options);
};

const createAnnouncment = (data: any) => {
  return client?.post(`/app/announcement`, data);
};

const getAllAnnouncement = () => {
  // console.log("tokennnnnn", token);

  // return axios.get("https://vault.lifebanktest.com/api/v1/app/announcement", {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  return client?.get(`/app/announcement`);
};

const getSingleAnnouncement = (id: any) => {
  return client?.get(`/app/announcement/${id}`);
};

const sop = () => {
  return client?.get(`/app/sop`);
};

const getSingleSop = (id: string) => {
  return client?.get(`/app/sop/${id}`);
};

const applyForRaise = (data: {
  name: string;
  designation: string;
  department: string;
  date: Date;
  comments: string;
  userId: number;
}) => {
  return client?.post(`/app/growth-raise`, data);
};

const changeDepartment = (data: {
  CurrentDepartment: string;
  CurrentRole: string;
  Reason: string;
  PreferredDepartment: string;
  Skill: string;
  Qualification: string;
}) => {
  return client?.post(`/app/change-department`, data);
};

const referFriend = (data: any) => {
  return client?.post(`/app/refer-friend`, data);
};

const getUserStat = () => {
  return client?.get("/app/users/statistics");
};

const employerOfMonth = (data: any) => {
  return client?.post(`/app/employee-of-month`, data);
};

const getEmployeeOfMonth = () => {
  return client?.get(
    `/app/employee-of-month/${[
      new Date().getMonth() + 1,
    ]}/${new Date().getFullYear()}`
  );
};

const getUser = () => {
  return client?.get(`/app/users`);
};
const getSingleUser = (id: number) => {
  return client?.get(`/app/user/${id}`);
};

const allRequest = () => {
  return client?.get("/app/requests");
};

const singleRequest = (id: number) => {
  return client?.get(`/app/requests/${id}`);
};

const treatRequest = (id: any, data: any) => {
  return client?.patch(`/app/requests/${id}`, data);
};

const raise = (data: any) => {
  return client?.post("/app/raise", data);
};

const fitIn = (data: any) => {
  return client?.post("/app/change-department", data);
};

const getNotification = (id: string) => {
  return client?.get(`/app/notifications/${id}`);
};

const markasRead = (id: any) => {
  return client?.get(`/app/notifications/mark/${id}`);
};

export {
  login,
  registerEmployee,
  deleteEmployee,
  editEmployee,
  createAnnouncment,
  getSingleAnnouncement,
  getAllAnnouncement,
  sop,
  getSingleSop,
  applyForRaise,
  changeDepartment,
  referFriend,
  employerOfMonth,
  getEmployeeOfMonth,
  getUser,
  getSingleUser,
  getUserStat,
  allRequest,
  singleRequest,
  treatRequest,
  raise,
  fitIn,
  getNotification,
  markasRead,
};
