import React, { FC } from 'react'
import { Form, Formik } from "formik"

interface AppFormProps {
    initialValues: any;
    onSubmit: (values: any) => void;
    validationSchema: any;
    children: React.ReactNode;
    className?: string;
    style?: any;
}

 const AppForm: FC<AppFormProps> = ({
    initialValues,
    onSubmit,
    validationSchema,
    children,
    className,
    style
}) => {
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {() => (
                <Form>
                    {children}
                </Form>
            )}
        </Formik>
    );
};

export default  AppForm;