import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { UserProvider } from "./context/cardContext";
import { GlobalContextProvider } from "./context/globalContext";
import { QueryProvider } from "./services/react-query/queryProvider";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryProvider>
      <ReactQueryDevtools />
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </QueryProvider>
  </React.StrictMode>
);
