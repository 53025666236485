"use client"
import React from 'react';
import Screen from '../../components/Screen';
import ProfileHeaderSection from '../../components/ProfileHeaderSection';
import HeaderDescription from '../../components/HeaderDescription';
import HeaderBox from '../../components/Box/HeaderBox';
import { FowardButton } from '../../components/forms/DashboardButton';
import { useNavigate } from 'react-router-dom';

// import { Lato } from "next/font/google";
// import { aboutImage } from '../image';
// // import Image from ''

// import HeaderDescription from '../components/HeaderDescription';
// import ProfileHeaderSection from '../components/ProfileHeaderSection';
// import { FowardButton, BackButton } from '../components/forms/DashboardButton';
// import Screen from '../components/Screen';
// import ProfileHeaderSection from '../components/ProfileHeaderSection';
// import HeaderDescription from '../components/HeaderDescription';
// import HeaderBox from '../components/Box/HeaderBox';
// import { aboutImage } from '../components/image';
// import HeaderBox from '../components/Box/HeaderBox';
// import Image from 'next/image';
// import Screen from '../components/Screen';


// const LatoFont = Lato({
//     subsets: ['latin'],
//     weight: ["700"],
// });

// const LatoFonts = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const EmployeeManual = () => {
    const navigate = useNavigate();


    const handleNext = () => {
        navigate("/how-to-work/benefit")
    }
    const handleReadMore = () =>{
        window.open('https://drive.google.com/file/d/1-ue_5t3CXqPqvYzj1LJ5xrOvQuHWL-df/view?usp=drive_link', '_blank');

    }

    return (
        <div className="bg-white w-full">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'Employee Manual'} />
                <p className={` text-4xl text-lightDark my-4`}>Employee Manual</p>
                <div className="">
                    <HeaderBox text="Revolutionizing African healthcare through technology" />
                </div>
                <div className={` text-black ml-2 mt-4 text-basetext leading-10 text-left`}>
                    The LifeBank Creed is the central repository for how LifeBank operates its
                    people. It is our guide and it provides clarity on the expectations of
                    employees and LifeBank. As LifeBank expands, change to the creed may be
                    needed. Employees will be notified of these changes as they occur. We also
                    recognize that the Creed may not cover all scenarios that may arise. Those
                    situations will be handled on a case by case basis. If there are any questions
                    or concerns, speak with your Team Lead or the Director of People, who will
                    provide you with clarity.
                </div>
                <p className="cursor-pointer mt-4 ml-2 text-lightRed" onClick={handleReadMore}>Read more...</p>

                {/* <div className="w-[70%] mx-auto flex justify-center"> */}
                {/* <div className="bg-lightDark flex flex-col h-80 rounded relative mt-4">
                    <div className="bg-lightRed h-40 w-[30%] ml-auto flex rounded relative"></div>
                    <div className="absolute h-auto top-4">
                        <h1 className={` text-white p-1 ml-10 py-4 text-xl`}>LifeBank mission</h1>

                        <p className=" text-white p-2 ml-9  rounded">
                            lifeBank is on a mission to save lives across Africa by delivering critical life-saving supplies like blood, oxygen,  medical samples and consumable , PPE and rare medicines to hospitals.
                        </p>
                    </div>

                   

                </div> */}
                <div className="my-4 flex justify-end absolute bottom-2 right-3">
                    <FowardButton text={'Next'} onClick={handleNext} subText={'Benefit'} />
                </div>
            </Screen>
        </div >
    )
}

export default EmployeeManual;