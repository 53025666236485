"use client"
import React from 'react';
import Screen from '../../components/Screen';
import ProfileHeaderSection from '../../components/ProfileHeaderSection';
import HeaderDescription from '../../components/HeaderDescription';
import AppForm from '../../components/forms/AppForm';
import { AppField } from '../../components/forms/Field';
import Button from '../../components/forms/Button';
// import { Lato } from "next/font/google";

// import Screen from '../components/Screen';
// import ProfileHeaderSection from '../components/ProfileHeaderSection';
// import HeaderDescription from '../components/HeaderDescription';
// import AppForm from '../components/forms/AppForm';
// import { AppField } from '../components/forms/Field';
// import Button from '../components/forms/Button';



// const LatoFont = Lato({
//     subsets: ['latin'],
//     weight: ["700"],
// });

// const SmallerFont = Lato({
//     subsets: ['latin'],
//     weight: ["400"],
// })

const InternalJobs = () => {
    return (
        <div className='bg-white w-full'>
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Growth'} subsubtext={'Internal Jobs'} />

                <div className="w-10/12 flex mx-auto mt-8  flex-col">
                    <p className={` text-2xl text-black`}>Internal Jobs</p>
                    <p className={`text-lightDark text-xl `}>One Team,One Dream: Building the future of Healthcare</p>

                    <div className="mt-8">
                        <AppForm initialValues={{ raise: '' }} onSubmit={function (values: any): void {
                            throw new Error('Function not implemented.');
                        }} validationSchema={undefined}>
                            <AppField name={"raise"} label={`Years of experience?`} height={28} />

                            <div className="mb-4 mt-8">
                                <AppField name={"okr"} label={`Qualification?`} height={12} />
                            </div>
                            {/* <div className="mb-4 mt-8">
                                <AppField name={"achieve"} label={`Current Achievement?`} height={12} />
                            </div>
                            <div className="mb-4 mt-8">
                                <AppField name={"salary"} label={`Please state the new net salary you will like?`} height={12} />
                            </div> */}
                            <div className="flex mx-auto justify-center ">
                                <Button width='60' text={'SUBMIT'} textColor='white' onClick={function (): void {
                                    throw new Error('Function not implemented.');
                                }} bg={'lightRed'} />

                            </div>
                        </AppForm>
                    </div>

                </div>
            </Screen>
        </div>
    )
}

export default InternalJobs;