import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function TransitionsModal({
  open,
  setOpen,
  children,
  style,
  title,
  handleCloseModal,
  handleBackdropClose,
  footer,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  title?: string;
  handleCloseModal?: () => void;
  handleBackdropClose?: () => void;
  style?: {
    position: string;
    top: string;
    left: string;
    transform: string;
    width?: number | string | undefined | any;
    bgcolor: string;
    boxShadow: number;
    borderRadius: string;
    p?: number;
    maxWidth?: number | string;
  };
  footer?: React.ReactNode;
}) {
  // const handleBackdropClose = () => setOpen(false);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleBackdropClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              position: style?.position ?? ("absolute" as const),
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: style?.width ?? {
                xs: "90%",
                sm: "80%",
                md: "60%",
                lg: "50%",
                xl: "40%",
              },
              maxWidth: style?.maxWidth ?? "800px",
              bgcolor: style?.bgcolor ?? "background.paper",
              boxShadow: 24,
              borderRadius: style?.borderRadius ?? "20px",
              p: style?.p ?? 4,
            }}
          >
            <div className="flex justify-between items-center w-full py-2 px-3">
              <h1 className="text-2xl font-semibold capitalize">{title}</h1>
              <button
                onClick={handleCloseModal}
                className="text-xl text-red-500 bg-red-50 rounded-full py-1 px-3"
              >
                &times;
              </button>
            </div>

            <div className="space-y-4 flex flex-col items-center text-center overflow-y-auto overflow-x-hidden max-h-96 scrollbar mt-3">
              {children}
            </div>

            {footer && (
              <div className="mx-auto flex justify-center w-full py-3">
                {footer}
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
