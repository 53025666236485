import React, { useEffect, useState } from 'react'
import ProfileHeaderSection from '../components/ProfileHeaderSection'
import HeaderDescription from '../components/HeaderDescription'
import Screen from '../components/Screen'
import { getSingleAnnouncement } from '../services/api'
import { useParams } from 'react-router-dom'

const SingleAnnoucement = () => {
  const { id } = useParams()
  const [data, setData] = useState([]) as any


  const fetch = async () => {
    if (id) {
      const response = await getSingleAnnouncement(id)
      setData(response?.data?.data)
    
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <div className="w-full">
      <Screen>
        <ProfileHeaderSection />
        <HeaderDescription maintext={'Dashboard'} subtext={'Annoucement'} subsubtext={'Annoucement'} />
        <p className={` text-4xl text-lightDark my-4`}>Annoucement</p>
        <div className="mt-8">
          <p className="text-2xl text-center bg-orange p-4 rounded capitalize">
            {data.title}
          </p>
          <div className="mt-4 text-basetext leading-9">
            {data?.content}
          </div>
        </div>
      </Screen>
    </div>
  )
}

export default SingleAnnoucement