import React, { useState } from "react";
import Screen from "../../components/Screen";
import ProfileHeaderSection from "../../components/ProfileHeaderSection";
import HeaderDescription from "../../components/HeaderDescription";
import AppForm from "../../components/forms/AppForm";
import { AppField } from "../../components/forms/Field";
import Button from "../../components/forms/Button";
import * as Yup from "yup";
import { createAnnouncment } from "../../services/api";
import LoaderComponent from "../../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../context/globalContext";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  content: Yup.string().required().label("Description"),
});

const CreateAnnouncement = () => {
  const { token } = useGlobalContext();
  const navigate = useNavigate();

  if (!token) {
    navigate("/sign-in");
  }

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value: any) => {
    setLoading(true);
    const res = await createAnnouncment(value);
    setLoading(false);
    toast.success("Announcement successfully created!", {});
    navigate("/dashboard");
  };

  return (
    <div className="w-full h-screen">
      <LoaderComponent loading={loading} />

      <Screen>
        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Admin"}
          subtext={"Growth"}
          subsubtext={"Create-Announcement"}
        />
        <div className="mt-24 mx-auto h-6/12 w-7/12">
          <AppForm
            initialValues={{ title: "", content: "" }}
            onSubmit={(values: any) => {
              handleSubmit(values);
              // handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            <AppField
              name={"title"}
              label={`Title of Announcement`}
              height={28}
            />

            <div className="mb-4 mt-8">
              <AppField
                name={"content"}
                label={`Description of Announcement`}
                as="textarea"
                cols="30"
                height="32"
              />
            </div>

            <div className="flex mx-auto justify-center ">
              <Button
                width="60"
                text={"SUBMIT"}
                type={"submit"}
                textColor="white"
                bg={"lightRed"}
              />
            </div>
          </AppForm>
        </div>
      </Screen>
    </div>
  );
};

export default CreateAnnouncement;
