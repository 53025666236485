"use client";
import React, { useState } from "react";
import Screen from "../../components/Screen";
import ProfileHeaderSection from "../../components/ProfileHeaderSection";
import HeaderDescription from "../../components/HeaderDescription";
import AppForm from "../../components/forms/AppForm";
import { AppField } from "../../components/forms/Field";
import Button from "../../components/forms/Button";
import * as Yup from "yup";
import { SelectField } from "../../components/forms/SelectField";
import { useGlobalContext } from "../../context/globalContext";
import { raise } from "../../services/api";
import LoaderComponent from "../../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  reason: Yup.string().required().label("raise"),
  okr: Yup.string().required().label("okr"),
  achievement: Yup.string().required().label("achieve"),
  expected_net: Yup.number().required().label("expected net"),
  role: Yup.string().required().label("role"),
  current_department: Yup.string().required().label("current department"),
});

const Raise = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, token } = useGlobalContext();
  const email = user?.email;
  const vaultId = user?.id;

  if (!token) {
    navigate("/sign-in");
  }
  const handleSubmit = async (values: any) => {
    setLoading(true);
    const response = await raise(values);

    setLoading(false);
    toast.success("Form successfully submitted", {});
    navigate("/dashboard");
  };

  return (
    <div className="bg-white w-full">
      <Screen>
        <LoaderComponent loading={loading} />
        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Dashboard"}
          subtext={"Growth"}
          subsubtext={"Raise"}
        />

        <div className="w-10/12 flex mx-auto mt-8  flex-col">
          <p className={` text-2xl text-black`}>Raise</p>
          <p className={`text-lightDark text-xl `}>
            One Team,One Dream: Building the future of Healthcare
          </p>

          <div className="mt-8">
            <AppForm
              initialValues={{
                reason: "",
                okr: "",
                email: email,
                achievement: "",
                expected_net: "",
                role: "",
                current_department: "",
                user_id: vaultId,
              }}
              onSubmit={(values: any) => {
                handleSubmit(values);
                // handleSubmit(values);
              }}
              validationSchema={validationSchema}
            >
              <AppField
                name={"reason"}
                label={`Why would you want a raise?`}
                height={28}
              />

              <div className="mb-4 mt-8">
                <AppField
                  name={"okr"}
                  label={`Your monthly okr?`}
                  height={12}
                  as="textarea"
                />
              </div>
              <div className="mb-4 mt-8">
                <AppField
                  name={"achievement"}
                  label={`Current Achievement?`}
                  height={12}
                />
              </div>
              <div className="mb-4 mt-8">
                <AppField
                  name={"expected_net"}
                  label={`Please state the new net salary you will like?`}
                  height={12}
                />
              </div>
              <div className="mb-4 mt-8">
                <AppField
                  name={"role"}
                  label={`Please state your role?`}
                  height={12}
                />
              </div>

              <div className="mb-4 mt-8">
                <SelectField
                  name={"current_department"}
                  label="Department"
                  options={departmentOptions}
                  placeholder="Select Department"
                />
              </div>

              <div className="flex mx-auto justify-center ">
                <Button
                  width="60"
                  text={"SUBMIT"}
                  textColor="white"
                  bg={"lightRed"}
                />
              </div>
            </AppForm>
          </div>
        </div>
      </Screen>
    </div>
  );
};

export default Raise;

const departmentOptions = [
  { value: 1, label: "People Operations" },
  { value: 2, label: "Engineering" },
  { value: 3, label: "Finance" },
  { value: 4, label: "Sales" },
  { value: 5, label: "Marketing" },
  { value: 6, label: "Communications" },
  { value: 7, label: "Customer Success" },
  { value: 8, label: "Other" },
];
