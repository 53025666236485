import React, { useState, useEffect } from "react";
import { notification } from "./image";
import NotificationModal from "./modal/NotificationModal";
import { getNotification } from "../services/api";
import { FaAsterisk } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { useGlobalContext } from "../context/globalContext";
import AOS from "aos";
import "aos/dist/aos.css";
import SideBar from "../layout/Sidebar";

const ProfileHeaderSection: React.FC = () => {
  const { user } = useGlobalContext();
  const [notify, setNotify] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0); 

  const image = user?.picture;
  const userdept = user?.department;
  const vaultId = user?.id;

  const handleNotification = () => {
    setNotify(true);
  };

  const fetchNotifications = async () => {
    try {
      if (vaultId) {
        const res = await getNotification(vaultId);
        let msg =
          userdept === "People Operations"
            ? res?.data?.data?.data
            : res?.data?.data;

        // Sort notifications by date in descending order (most recent first)
        msg = msg?.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        // Calculate unread notifications count
        const unread = msg?.filter(
          (notification: any) => notification.is_read === "0"
        ).length;
        setUnreadCount(unread);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const handleMobileNav = () => {
    setExpandedMenu(!expandedMenu);
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="">
      {expandedMenu && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-[rgba(136,133,133,0.7)]"
          data-aos="fade-right"
          data-aos-duration="700"
          data-aos-easing="linear"
        >
          <div
            className="h-[100%] lg:w-[20%] shadow-md z-50 bg-white rounded-xl p-4 fixed top-1/2 left-2 transform -translate-y-1/2 cursor-auto "
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="linear"
          >
            <SideBar handleMobileNav={handleMobileNav} />
          </div>
        </div>
      )}

      {notify && (
        <div>
          <NotificationModal setNotify={setNotify} />
        </div>
      )}
      <div className="fixed top-0 w-[98%] lg:w-full z-30 shadow-md bg-white lg:static lg:bg-transparent lg:shadow-none">
        <div className="flex justify-between lg:justify-end px-2 mt-2 mb-2 items-center">
          <button onClick={handleMobileNav} className="lg:hidden">
            <GiHamburgerMenu className="font-semibold" />
          </button>
          <div className="flex">
            <span
              className="shadow-md w-8 h-8 mx-2 flex items-center justify-center rounded cursor-pointer relative"
              onClick={handleNotification}
            >
              <img src={notification} alt="notification" />
              {unreadCount > 0 && (
                <p className="text-bloodRed absolute font-black bottom-5 left-[21.5px]">
                  <FaAsterisk size={10} />
                </p>
              )}
            </span>
            <span className="shadow-md w-8 h-8 mx-2 flex items-center justify-center rounded">
              {image && (
                <img src={image} alt="user avatar" className="rounded" />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeaderSection;
