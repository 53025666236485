"use client"
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useRouter } from 'next/navigation'
// import Screen from '../components/Screen'
// import ProfileHeaderSection from '../components/ProfileHeaderSection'
// import HeaderDescription from '../components/HeaderDescription'
// import { Lato } from "next/font/google";
// import HeaderBox from '../components/Box/HeaderBox';
// import { mission } from "../image"
// import Image from 'next/image';
// import { FowardButton, BackButton } from '../components/forms/DashboardButton';
// import ClickableCircle from '../components/ClickableCircle'

import AOS from "aos";
import "aos/dist/aos.css";

import Screen from '../../components/Screen';
import ProfileHeaderSection from '../../components/ProfileHeaderSection';
import HeaderDescription from '../../components/HeaderDescription';
import HeaderBox from '../../components/Box/HeaderBox';
// import ClickableCircle from '../../components/ClickableCircle';
import { newVpGrowth, vpGrowth } from '../../components/image';

import { BackButton, FowardButton } from '../../components/forms/DashboardButton';




const VpGrowth = () => {
    const navigate = useNavigate();
    // const router = useRouter()
    const handleNext = () => {
        navigate("/lbn")
    }

    const handlePrev = () => {
       
        navigate("/organization")
      
    }

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="w-full">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'Mission & Vison'} />

                <div>
                    <p className={`text-lightDark text-3xl mt-2 mb-2 `}>Organization Chart</p>
                </div>
                <div className="">
                    <HeaderBox text="A Visual guide into Lifebank structure and hierarchy" />
                </div>
                

                <div className="mt-6 w-[90%] h-[30%] flex items-center justify-center mx-auto"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                >

                    <img src={newVpGrowth} alt="helll" className="w-[99%] h-[40%] object-fit" />
                </div>

                <div className="my-2 flex bottom-2 gap-4 w-full justify-end">
                    <BackButton text={'Previous'} onClick={handlePrev} subText={'Organisation Chart'} />
                    <FowardButton text={'Next'} onClick={handleNext} subText={'CEO LBN Chart'} />
                </div>
            </Screen>
        </div>
    )
}

export default VpGrowth;


