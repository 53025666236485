import axios from "axios";
import { getAccessTokenFromCookies } from "../helper";

// Define the base URL for your API
const baseURL = "https://vault.lifebanktest.com/api/v1";

// Create an Axios client instance
const client = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Interceptor to attach the token to the headers of each request
client.interceptors.request.use(
  (config) => {
    const token = getAccessTokenFromCookies();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle responses and errors
client.interceptors.response.use(
  (response) => response,
  (error) => {
    // Optionally, you could add some error handling logic here
    return Promise.reject(error);
  }
);

export default client;
