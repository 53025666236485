import React from "react";
// import { Lato } from "next/font/google";


interface circleProps {
    title: string;
    onClick?: () => void;
    margin?: any;
}


const ClickableCircle = ({ title, onClick, margin }: circleProps) => {
    return (
        <div className={`h-32 w-32 mt-${margin} p-2 rounded-[8rem] shadow-md bg-white flex item-center mx-auto justify-center text-lightDark hover:cursor-pointer hover:transition  hover:duration-300 ease-in-out hover:bg-opacity-80`} onClick={onClick}>
            <p className={`text-lightDark hover:text-white text-center flex items-center justify-center `}>{title}</p>
        </div>

    )
}


const Clickablebox = ({ title, onClick, margin }: circleProps) => {
    return (
        <div className={`h-20 w-40 mt-${margin}   rounded shadow-md bg-white flex item-center mx-auto justify-center text-lightDark hover:cursor-pointer hover:transition  hover:duration-300 ease-in-out hover:bg-bloodRed hover:text-white`} onClick={onClick}>
            <p className={`text-lightDark px-5 py-4 hover:text-white text-center flex items-center justify-center `}>{title}</p>
        </div>
    )
}

export {
    ClickableCircle, Clickablebox
}