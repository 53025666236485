import React from "react";
import Button from "./forms/Button";
import ProfileHeaderSection from "./ProfileHeaderSection";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/globalContext";
import { deleteAuthUser } from "../helper";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const name = user?.firstname;

  const handleLogout = () => {
    deleteAuthUser();
    navigate("/sign-in");
  };
  function getGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }
  return (
    <div>
      <ProfileHeaderSection />
      <div className="bg-bloodRed rounded-tr-xl rounded-bl-xl lg:mt-2 mt-14 w-full">
        <div className="text-white px-8 py-3">
          <div>
            <p className={`md:text-3xl  text-left font-lato`}>Welcome back! </p>
            <p className={`md:text-2xl text-left font-lato`}>
              {getGreeting()} {name},
            </p>
          </div>
          <div className="flex flex-row w-full mx-auto justify-between  h-16 mt-2">
            <div className="flex flex-col">
              <p className={`md:text-3xl text-left}`}>Check In</p>
              <p className={`text-left`}>General 09:00am to 05:00pm</p>
            </div>
            <div className="flex flex-row gap-2">
              <Button
                text="Log Out"
                onClick={handleLogout}
                bg="white"
                width={"28"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
