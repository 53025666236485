import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  registerEmployee,
  editEmployee,
  deleteEmployee,
  getUser,
} from "../services/api";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const useGetEmployees = () => {
  const { data, isPending, isError, error } = useQuery({
    queryKey: ["get-employee"],
    queryFn: async () => {
      const response = await getUser();
      return response;
    },
  });
  console.log(data);
  const employee = data?.data?.data;

  return { employee, isPending, isError, error };
};
export const useRegisterEmployee = () => {
  const queryClient = useQueryClient();
  const {
    isPending,
    mutate: mutateRegisterEmployee,
    error,
    isError,
    isSuccess,
    data,
  } = useMutation({
    mutationFn: async (payload: any) => {
      const response = await registerEmployee(payload);
      return response;
    },
    mutationKey: ["register-employee"],
    onSuccess: (data) => {
      if (data) {
        toast.success("Event successfully created!");
        queryClient.invalidateQueries({ queryKey: ["get-employee"] });
      }
    },
    onError: (error: { message: string }) => {
      toast.error(error.message || "Failed to create event");
    },
  });
  return {
    isPending,
    mutateRegisterEmployee,
    error,
    isError,
    isSuccess,
    data,
  };
};

export const useEditEmployee = () => {
  const {
    isPending,
    mutate: mutateEditEmployee,
    error,
    isError,
    isSuccess,
    data,
  } = useMutation({
    mutationFn: (payload: any) => editEmployee(payload),
    mutationKey: ["edit-employee"],
    onSuccess(data: AxiosResponse) {
      toast.success("Employee successfully updated!");
    },
    onError: (error: AxiosResponse) => {
      console.log(error);
      toast.error("Account not updated, please contact the HR.");
    },
  });
  return {
    isPending,
    mutateEditEmployee,
    error,
    isError,
    isSuccess,
    data,
  };
};

export const useDeleteEmployee = () => {
  const {
    isPending,
    mutate: mutateDeleteEmployee,
    error,
    isError,
    isSuccess,
    data,
  } = useMutation({
    mutationFn: (payload: any) => deleteEmployee(payload),
    mutationKey: ["delete-employee"],
    onSuccess(data: AxiosResponse) {
      toast.success("Employee successfully deleted!");
    },
    onError: (error: AxiosResponse) => {
      console.log(error);
      toast.error("Account not deleted, please contact the HR.");
    },
  });
  return {
    isPending,
    mutateDeleteEmployee,
    error,
    isError,
    isSuccess,
    data,
  };
};
