"use client"
import React from 'react';
import { FowardButton } from '../components/forms/DashboardButton';
import Screen from '../components/Screen';
import ProfileHeaderSection from '../components/ProfileHeaderSection';
import HeaderDescription from '../components/HeaderDescription';
import HeaderBox from '../components/Box/HeaderBox';
import { aboutImage } from '../components/image';

import { useNavigate } from 'react-router-dom';
const About = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("/mission")
    }

    return (
        <div className="bg-white w-full">
            <Screen>
                <ProfileHeaderSection />
                <HeaderDescription maintext={'Dashboard'} subtext={'Our Team'} subsubtext={'About LifeBank'} />
                <p className={` text-4xl text-lightDark my-4`}>About LifeBank</p>
                <div className="">
                    <HeaderBox text="Revolutionizing African healthcare through technology" />
                </div>
                <div className={` text-black  mt-4 text-basetext leading-7 font-lato text-left`}>
                    LifeBank is {`Africa's`} foremost healthcare technology and logistics company. It powers hospitals and care centers to deliver quality healthcare to patients in emerging markets. The {`company's`} technology, infrastructure, agile production, and distribution networks enable hospitals in emerging markets to catch up with their global peers. LifeBank is the technology-led healthcare system for the future. It is on a mission to create the infrastructural backbone for the African healthcare system. On its journey to being Pan-African, it is present in three countries: Kenya, Nigeria and Ethiopia; where it has saved over 178,000 lives through the delivery of over 52,000 essential medical products to 1,380+ hospitals.
                </div>

                <div className="bg-lightDark flex flex-col h-80 rounded relative mt-4">
                    <div className="bg-lightRed h-40 w-[30%] ml-auto flex rounded relative"></div>
                    <div className="absolute h-auto top-4">
                        <h1 className={` text-white p-1 ml-10 py-4 text-xl`}>LifeBank mission</h1>
                        <div className="flex mx-auto justify-center w-11/12">
                            <img src={aboutImage} alt="team/about" className="rounded h-40 w-full object-fill" />
                        </div>
                        <p className=" text-white p-2 ml-9  rounded">
                            LifeBank is on a mission to save lives across Africa by delivering critical life-saving supplies like blood, oxygen,  medical samples and consumable , PPE and rare medicines to hospitals.
                        </p>
                    </div>


                </div>
                <div className="my-4 flex justify-end">
                    <FowardButton text={'Next'} onClick={handleNext} subText={'Mission & Vision'} />
                </div>
            </Screen>
        </div >
    )
}

export default About;