import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard";
import About from "../pages/About";
import Mission from "../pages/Mission";
import Values from "../pages/Values";
import North from "../pages/North";
import Annual from "../pages/Annual";
import Quarterly from "../pages/QuarterlyGoals";
import Organization from "../pages/organization/Organization";
import CTOpage from "../pages/organization/CTO";
import Finance from "../pages/organization/Finance";
import VpGrowth from "../pages/organization/VpGrowth";
import LifeBankNigeria from "../pages/organization/LifeBankNigeria";
import EmployeeManual from "../pages/howToWork/EmployeeManual";
import Benefits from "../pages/howToWork/Benefit";
import Raise from "../pages/Growth/Raise";
import FitIn from "../pages/Growth/FitIn";
import InternalJobs from "../pages/Growth/InternalJobs";
import Refer from "../pages/Growth/Refer";
import Directory from "../pages/Directory";
import Report from "../pages/Report";
import CreateAnnouncement from "../pages/Hr/CreateAnnouncement";
import CreateEmployee from "../pages/Hr/CreateEmployee";
import ComingSoon from "../pages/ComingSoon";
import SingleAnnoucement from "../pages/SingleAnnoucement";
import Opperations from "../pages/organization/Opperations";
import Boat from "../pages/organization/Boat";
import Comms from "../pages/organization/Comms";
import LifeBankEthiopia from "../pages/organization/LifeBankEthiopia";
import People from "../pages/organization/People";
import LifeBankKenya from "../pages/organization/LifeBankKenya";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route
          // @ts-ignore
          exact
          path="/"
          element={<Navigate replace to="/sign-in" />}
        />
        <Route
          path="/dashboard"
          element={
            <MainLayout>
              <Dashboard />
            </MainLayout>
          }
        />
        <Route
          path="/about"
          element={
            <MainLayout>
              <About />
            </MainLayout>
          }
        />
        <Route
          path="/mission"
          element={
            <MainLayout>
              <Mission />
            </MainLayout>
          }
        />
        <Route
          path="/value"
          element={
            <MainLayout>
              <Values />
            </MainLayout>
          }
        />
        <Route
          path="/directory"
          element={
            <MainLayout>
              <Directory />
            </MainLayout>
          }
        />
        <Route
          path="/true-north"
          element={
            <MainLayout>
              <North />
            </MainLayout>
          }
        />
        <Route
          path="/annual-goals"
          element={
            <MainLayout>
              <Annual />
            </MainLayout>
          }
        />
        <Route
          path="/quarterly-goals"
          element={
            <MainLayout>
              <Quarterly />
            </MainLayout>
          }
        />
        <Route
          path="/organization"
          element={
            <MainLayout>
              <Organization />
            </MainLayout>
          }
        />
        <Route
          path="/cto"
          element={
            <MainLayout>
              <CTOpage />
            </MainLayout>
          }
        />
        <Route
          path="/finance"
          element={
            <MainLayout>
              <Finance />
            </MainLayout>
          }
        />
        <Route
          path="/vp-growth"
          element={
            <MainLayout>
              <VpGrowth />
            </MainLayout>
          }
        />
        <Route
          path="/lbn"
          element={
            <MainLayout>
              <LifeBankNigeria />
            </MainLayout>
          }
        />
        <Route
          path="/lbe"
          element={
            <MainLayout>
              <LifeBankEthiopia />
            </MainLayout>
          }
        />
        <Route
          path="/lbk"
          element={
            <MainLayout>
              <LifeBankKenya />
            </MainLayout>
          }
        />
        <Route
          path="/operations"
          element={
            <MainLayout>
              <Opperations />
            </MainLayout>
          }
        />
        <Route
          path="/boat"
          element={
            <MainLayout>
              <Boat />
            </MainLayout>
          }
        />
        <Route
          path="/communication"
          element={
            <MainLayout>
              <Comms />
            </MainLayout>
          }
        />

        <Route
          path="/people"
          element={
            <MainLayout>
              <People />
            </MainLayout>
          }
        />
        <Route
          path="/how-to-work/employee-manual"
          element={
            <MainLayout>
              <EmployeeManual />
            </MainLayout>
          }
        />
        <Route
          path="/how-to-work/benefit"
          element={
            <MainLayout>
              <Benefits />
            </MainLayout>
          }
        />
        <Route
          path="/growth/raise"
          element={
            <MainLayout>
              <Raise />
            </MainLayout>
          }
        />
        <Route
          path="/growth/fit-in"
          element={
            <MainLayout>
              <FitIn />
            </MainLayout>
          }
        />
        <Route
          path="/growth/intenal-jobs"
          element={
            <MainLayout>
              <InternalJobs />
            </MainLayout>
          }
        />
        <Route
          path="/growth/refer-a-friend"
          element={
            <MainLayout>
              <Refer />
            </MainLayout>
          }
        />
        <Route
          path="/reports"
          element={
            <MainLayout>
              <Report />
            </MainLayout>
          }
        />
        <Route
          path="/admin/create-announcement"
          element={
            <MainLayout>
              <CreateAnnouncement />
            </MainLayout>
          }
        />

        <Route
          path="/admin/employee-month"
          element={
            <MainLayout>
              <CreateEmployee />
            </MainLayout>
          }
        />
        <Route
          path="/coming-soon"
          element={
            <MainLayout>
              <ComingSoon />
            </MainLayout>
          }
        />
        <Route
          path="/single-announcement/:id"
          element={
            <MainLayout>
              <SingleAnnoucement />
            </MainLayout>
          }
        />
      </Routes>
    </Router>
  );
};
