import React, { CSSProperties } from "react";
import BounceLoader from "react-spinners/DotLoader";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "green",
};

const Loader = () => {
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50 bg-[rgba(255,255,255,0.7)]">
            <BounceLoader
                color={"#dd4040"}
                size={250}
                aria-label="Loading Spinner"
                data-testid="white"
            />
        </div>
    )
}

export default Loader;