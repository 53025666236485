"use client";
import React from "react";
import Screen from "../components/Screen";
import ProfileHeaderSection from "../components/ProfileHeaderSection";
import HeaderDescription from "../components/HeaderDescription";

import { FowardButton, BackButton } from "../components/forms/DashboardButton";
import HeaderBox from "../components/Box/HeaderBox";
import { mission } from "../components/image";
import { useNavigate } from "react-router-dom";

const Mission = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/value");
  };
  const handlePrev = () => {
    navigate("/about");
  };

  return (
    <div>
      <Screen>
        <ProfileHeaderSection />
        <HeaderDescription
          maintext={"Dashboard"}
          subtext={"Our Team"}
          subsubtext={"Mission & Vison"}
        />
        <p className={`  text-4xl text-lightDark my-4`}>Mission & Vision</p>
        <div className="">
          <HeaderBox text="One Team, One Dream: Building the Future of Healthcare " />
        </div>
        <div className={`  text-black mt-4 text-basetext leading-7 text-left`}>
          LifeBank is {`Africa's`} foremost healthcare technology and logistics
          company. It powers hospitals and care centers to deliver quality
          healthcare to patients in emerging markets.
        </div>
        <div className="mt-6 flex w-full flex-col lg:flex-row gap-4">
          <div className="bg-lightDark p-2 rounded lg:w-6/12">
            <p className={`  text-4xl text-white `}>Our Mission</p>
            <img
              src={mission}
              alt="mission"
              className=" rounded-md lg:w-11/12 shadow-lg h-80"
            />
          </div>
          <div className="lg:w-6/12">
            <div>
              <p className={` text-4xl text-black`}>What we aim for!</p>
            </div>
            <div className=" border border-gray  w-full" />

            {data.map((ele: { text: string; id: string }) => {
              return (
                <div
                  key={ele.id}
                  className="flex flex-row px-4 py-1 my-2 hover:bg-[#fbc2c2] rounded transition-all duration-700 cursor-auto"
                >
                  <div className={` text-black text-4xl mx-4`}>{ele.id}</div>
                  <div className={` text-black text-md mx-2`}>{ele.text}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-2 flex gap-4 w-full justify-end">
          <BackButton
            text={"Previous"}
            onClick={handlePrev}
            subText={"About page"}
          />
          <FowardButton text={"Next"} onClick={handleNext} subText={"Values"} />
        </div>
      </Screen>
    </div>
  );
};

export default Mission;

const data = [
  {
    id: "01",
    text: "LifeBank is on a mission to save lives across Africa by delivering critical life-saving supplies like blood, oxygen,  medical samples and consumables, PPE and rare medicines to hospitals in Africa using technology and a multi-modal distribution platform",
    title: "Dr. John Doe",
  },
  {
    id: "02",
    text: "LifeBank is on a mission to save lives across Africa by delivering critical life-saving supplies like blood, oxygen,  medical samples and consumables, PPE and rare medicines to hospitals in Africa using technology and a multi-modal distribution platform",
    title: "Dr. John Doe",
  },
  {
    id: "03",
    text: "LifeBank is on a mission to save lives across Africa by delivering critical life-saving supplies like blood, oxygen,  medical samples and consumables, PPE and rare medicines to hospitals in Africa using technology and a multi-modal distribution platform",
    title: "Dr. John Doe",
  },
];
